import React from 'react'
import Footer from '../component/Footer'
import Header from '../component/Header'
import home7 from '../assets/images/Home_07.png'
import home3 from '../assets/images/Home_03.png'
import home11 from '../assets/images/Home_11.png'
import home15 from '../assets/images/Home_15.png'
import service3 from '../assets/images/Services_03.png'
import gif from '../assets/images/icons updated2.gif'


import pic1 from '../assets/images/DoDeliver-1-min.png'
import pic2 from '../assets/images/DoDeliver-2-min.png'
import pic3 from '../assets/images/DoDeliver-3-min.png'
import pic4 from '../assets/images/DoDeliver-4-min.png'
import pic5 from '../assets/images/Home_07.png'
import pic6 from '../assets/images/DoDeliver-6-min.png'
import homepopup3 from '../assets/images/Home-Pop-up_03.png'





//log img
import cl1 from '../assets/images/1mand.png'
import cl2 from '../assets/images/2mand.png'
import cl3 from '../assets/images/3mand.png'
import cl4 from '../assets/images/4mand.png'
import cl5 from '../assets/images/5mand.png'
import cl6 from '../assets/images/6mand.png'
import cl7 from '../assets/images/7mand.png'
import cl8 from '../assets/images/8mand.png'
import cl9 from '../assets/images/9mand.png'
import cl10 from '../assets/images/rc1.png'
import cl11 from '../assets/images/rc2.png'
import cl12 from '../assets/images/rc3.png'
import cl13 from '../assets/images/rc4.png'


import client1 from '../assets/images/client14.png'
import client2 from '../assets/images/client15.png'
import client3 from '../assets/images/client16.png'
import client4 from '../assets/images/client17.png'
import client5 from '../assets/images/client18.png'
import client6 from '../assets/images/client19.png'
import client7 from '../assets/images/client20.png'


//
// import iconupdate from '../assets/images/images/icons updated2.gif'
import iconupdate from '../assets/images/icons updated2.gif'

//image folder
import callcenter from '../assets/images/images_resize/CallCenterSolution-min.png'
import Channelmanagement from '../assets/images/images_resize/Channelmanagement-min.png'
import payment from '../assets/images/images_resize/Fastest Payments.png'
import warehouse from '../assets/images/images_resize/Warehousingandfulfillment-min.png'
import returnmanagement from '../assets/images/images_resize/ReturnsManagement-01.png'
import techops from '../assets/images/images_resize/TechOps_v.png'
import verticalinternet from '../assets/images/images_resize/VerticallyIntegratedNetwork.png'

const Index = () => {
  return (
    <div >
      <Header />
      <div>
        <section id="slider" style={{ background: '#fbfbfb' }} className="slider-parallax swiper_wrapper full-screen clearfix">
          <div className="swiper-container swiper-parent mb-3">
            <div className="swiper-wrapper">
              <div className="swiper-slide"  >
                <div className="container clearfix">
                  <div className="slider-caption image">
                    <img className="img-fluid" src={home3} />
                  </div>
                  <div className="slider-caption text-center">
                    <img style={{ marginLeft: '-10px', width: '80%' }} src={iconupdate} />
                    <h2 data-caption-animate="fadeInUp">YOUR <span>TRUSTED</span> LOGISTICS <br />
                      <small>SATISFICATION <span>PARTNERS!</span></small></h2>
                    <p>Handling pressure and taking on board riders on the eleventh hour it's
                      our expertise & we proudly deliver. Aiming to achieve the goals and agendas of our clients.</p>
                    <button type="button" className="btn brown-bg-button" value="Track Your Order" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div id="counter" className="parallax icons-wapper pt-5 pb-5" style={{ backgroundColor: '#f5f5f5' }}>
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div data-aos="fade-zoom-in" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-wrap text-center">
                  <img alt="pic4" width={200} src={pic4} />
                  <h5 className="mt-3"><span className="counter-value" data-count={300}>0</span>+<br /> Destinations</h5>
                </div>
                <div data-aos="fade-zoom-in" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-wrap text-center">
                  <img alt="pic5" width={200} src={pic5} />
                  <h5 className="mt-3"><span className="counter-value" data-count={500}>0</span>+ <br /> Team Members</h5>
                </div>
                <div data-aos="fade-zoom-in" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-wrap text-center">
                  <img alt="pic6" width={200} src={pic6} />
                  <h5 className="mt-3"><span className="counter-value" data-count={340}>0</span>+<br />Vehicles on Route
                  </h5>
                </div>
                <div data-aos="fade-zoom-in" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-wrap text-center">
                  <img alt="pic3" width={200} src={pic3} />
                  <h5 className="mt-3"><span className="counter-value" id="stats_delivered" data-count={5470376}>0</span>
                    <br /> Shipment Delivered</h5>
                </div>
                <div data-aos="fade-zoom-in" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-wrap text-center">
                  <img alt="pic2" width={200} src={pic2} />
                  <h5 className="mt-3"><span className="counter-value" data-count={2301}>0</span> <br /> Shippers</h5>
                </div>
                <div data-aos="fade-zoom-in" className="col-lg-4 col-md-4 col-sm-4 col-xs-4 icon-wrap text-center">
                  <img alt="pic1" width={200} src={pic1} />
                  <h5 className="mt-3"><span className="counter-value" id="stats_cash_handled" data-count={12165545740}>0</span> <br /> Cash Handled</h5>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        <section>
          <div className="parallax-wrap pt-5">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div data-aos="zoom-out-right" className="col-xs-12 col-sm-6 col-md-6 col-lg-6 ">
                  <img alt="home7" src={home7} />
                </div>
                <div data-aos="zoom-out-left" className="col-xs-12 col-sm-6 col-md-6 col-lg-6 form-wrap">
                  <div className>
                    <div className="vc_custom_heading style2 vc_custom_1436253093658">
                      <h2 style={{ color: '#000', textAlign: 'left' }}>Cash on Delivery</h2>
                    </div>
                    <p className="text-left text-brown">Today's most in demand service is cash on delivery service that is offered by Do Deliver. The trend of online shopping is constantly evolving and the demand for safe shopping products is also increasing at the customer's doorstep. Payment is only applicable upon delivery and the online store can choose from the different functions that Do Deliver provides to make the service pack plan more convenient.</p>
                    {/* <button type="button" className="btn brown-bg-button" data-toggle="modal" data-target=".CashOnDelivery">See More</button> */}
                    <button type="submit" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="parallax-wrap pt-5 pb-5">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div data-aos="fade-right" className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div className>
                    <div className="vc_custom_heading style2 vc_custom_1436253093658">
                      <h2 className="pt-5" style={{ color: '#000', textAlign: 'left' }}>Logistics Cargo Industry </h2>
                    </div>
                    <p className="text-left text-brown">Do Deliver, a rising name in the logistics and cargo industry that mainly deals in wholesale riders’ services and COD (cash on delivery) facilities. Syed Saad Ali is Co-Founder and CEO of a company and Syed Osama Ali is a Co-Founder and operation director of a company since 2017, the upcoming largest delivery organization in Pakistan. They believe in empowering teams and relies on teamwork to achieve their goals. It also supports an open, non-bureaucratic culture and makes interaction with all departments of the organization important. This is believed to be the most effective way to find long-term solutions and improve employee morale by finding an accessible approach.</p>
                    {/* <button type="button" className="btn brown-bg-button" data-toggle="modal" data-target=".Omni-Channel">See More</button> */}
                    <button type="submit" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                </div>
                <div data-aos="fade-left" className="col-xs-12 col-sm-6 col-md-6 col-lg-6 order-first order-sm-last">
                  <img alt="home11" src={home11} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="parallax-wrap pb-5">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div data-aos="fade-left" className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <img alt="service3" src={service3} className="img-fluid" />
                </div>
                <div data-aos="fade-right" className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div className>
                    <div className="vc_custom_heading style2 vc_custom_1436253093658">
                      <h2 className="pt-5" style={{ color: '#000', textAlign: 'left' }}>Restaurants Delivery Management</h2>
                    </div>
                    <p className="text-left text-brown">Do Deliver has helped restaurants who always ended up hiring riders lacking fundamental and essential ethics but also were highly unprofessional. Being in the industry full of scams, Do Deliver ensures no fraudulent acts in terms of payments, CODs, late or no deliveries at all after taking money. Do Deliver currently is working with most prestigious and reputable food chains and franchises in Karachi.</p>
                    {/* <button className="btn brown-bg-button" data-toggle="modal" data-target=".360-DegreEcommerce">See More</button> */}
                    <button type="submit" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="parallax-wrap pt-5 pb-5">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div data-aos="fade-right" className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div className>
                    <div className="vc_custom_heading style2 vc_custom_1436253093658">
                      <h2 className="pt-5" style={{ color: '#000', textAlign: 'left' }}>Services</h2>
                    </div>
                    <p className="text-left text-brown">Connecting people to goods, services, ideas, and technology will create opportunities, enhance innovation, stimulate business vitality and improve the standard of living for society. At Do Deliver, we believe the connected world is a better world and this belief guides us for everything we do. Do Deliver continues to support you, while ensuring the safety of our customers and team members. We use our network to help our customers manage products more efficiently for their satisfaction.</p>
                    <button type="submit" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                  </div>
                </div>
                <div data-aos="fade-left" className="col-xs-12 col-sm-6 col-md-6 col-lg-6 order-first order-sm-last">
                  <img alt="home15" src={home15} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="section nobottommargin nobottomborder" style={{ backgroundColor: '#f5f5f5' }}>
            <div className="container clearfix">
              <div className="heading-block center nomargin">
                <h3>PROUDLY WORKING WITH....</h3>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix" />
        <section>
          <div className="container-fluid pb-5 clients-logo">
            <div className="row no-gutters justify-content-center text-center">
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl1" src={cl1} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl2" src={cl2} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl3" src={cl3} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl4" src={cl4} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl5" src={cl5} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl6" src={cl6} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl7" src={cl7} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl8" src={cl8} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl9" src={cl9} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl10" src={cl10} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl11" src={cl11} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl12" src={cl12} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={cl13} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client1} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client2} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client3} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client4} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client5} />
                </div>
              </div>
              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client6} />
                </div>
              </div>

              <div data-aos="zoom-in" className="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2">
                <div className="p-2 p-md-5 border-logo">
                  <img alt="cl13" src={client7} />
                </div>
              </div>

            </div>
          </div>
        </section>
        <section>
          <div className="topmargin nobottommargin nobottomborder section" style={{ paddingBottom: '0px' }}>
            <div className="Contact-map nobottommargin ">
              <div className="container clearfix">
                <div className="heading-block center">
                  <h3>FIND US HERE</h3>
                </div>
              </div>
              <div className="row">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3620.1261450427533!2d67.06005191500273!3d24.859540884054432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDUxJzM0LjQiTiA2N8KwMDMnNDQuMSJF!5e0!3m2!1sen!2s!4v1587685879995!5m2!1sen!2s" width="100%" height={600} />
              </div>
            </div>
          </div>
        </section>

        <div className="modal fade CashOnDelivery" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h3 className="modal-title w-100">CASH ON DELIVERY</h3>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                      <p className="text-center"><img alt="image" src={payment} className="img-responsive" /></p>
                      <h3>Fastest Payments</h3>
                      <p>Do Deliver offers the fastest cash payback of 24 hours, which helps you maintain your cash
                        flows and scale your business simultaneously.</p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                      <p className="text-center"><img alt="image" src={returnmanagement} className="img-responsive" /></p>
                      <h3>Returns Management</h3>
                      <p>Our unique services of Delivery Return Intercept, 100% Verified Returns, Reattempt
                        Management module, Reverse Pickups and Replacement Deliveries help you manage your
                        shipments efficiently and effectively.</p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                      <p className="text-center"><img alt="image" src={verticalinternet} className="img-responsive" /></p>
                      <h3> Vertically Integrated Network</h3>
                      <p>Do Deliver ensures efficient movement of goods from the first mile to the last mile across
                        Pakistan through a dedicated supply chain network, designed to match ever-changing
                        e-commerce logistics demands.</p>
                    </div>
                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                      <p><img alt="image" src={techops} className="img-responsive" /></p>
                      <h3>TechOps</h3>
                      <p>As an e-commerce logistics solution provider, we employ the ‘TechOps’ methodology
                        that directs us to constantly use technological advances in improving, facilitating
                        and opening innovative ways of providing services through our core Operations.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade Omni-Channel" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h3 className="modal-title w-100">Logistics Cargo Industry</h3>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-12 col-md-12-col-lg-12-col-sm-12 text-center">
                      <img alt="image" src={Channelmanagement} className="img-responsive" />
                      <p>Our Logistics Cargo Industry management system makes it easy to dispatch orders from
                        multiple retail outlets and vendors utilizing the store locations and inventories
                        for swift deliveries at lower costs. Designed specifically for online marketplaces
                        as well as growing retail brands, this solution enables them to use the potential of
                        their existing retail infrastructure to manage their online businesses.</p>
                      <p>The solution offers brands to use their retail stores as fulfillment centers and
                        shipping orders from the closest possible solution to the customer. The benefits of
                        such an approach is not just limited to optimization, but allows a brand to scale
                        more effectively and efficiently.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade Warehousing" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h3 className="modal-title w-100">WAREHOUSING</h3>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-12 col-md-12-col-lg-12-col-sm-12 text-center">
                      <img src={warehouse} className="img-responsive" style={{ maxWidth: '250px' }} />
                      <p>Do Deliver's unique E-tail Warehousing solution comes at rescue for your e-commerce
                        business, with complete control of order fulfilment and stock management.</p>
                      <p>Our warehousing system ensures timely dispatches and providing real-time data
                        analytics for stock and order management, along with integration to your various
                        e-commerce platforms, including native and third party websites
                        and apps.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade Fulfilment" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h3 className="modal-title w-100">FULFILMENT</h3>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-xs-12 col-md-12-col-lg-12-col-sm-12 text-center">
                      <img alt="image" src="" className="img-responsive" />
                      <p>Our state-of-the-art fulfilment system ensures that every order request is being
                        properly picked, passed through quality checks and fulfilled.</p>
                      <p>Do Deliver’s fulfilment solution ranges from dedicated warehousing to omni-channel
                        inventory management, using the reach of retail and incorporating the seamless
                        experience of e-commerce management, ensuring reliable and real-time inventory
                        management, wherever and whenever needed.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>

      <Footer />
    </div>
  )
}

export default Index
