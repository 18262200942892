import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Aboutus03 from "../assets/images/About-us_03.png";
import Aboutus07 from "../assets/images/About-us_07.png";


import mand1 from "../assets/images/images_resize/profile1.png";
import mand2 from "../assets/images/images_resize/profile2.png";
import mand3 from "../assets/images/images_resize/profile3.png";
import mand4 from "../assets/images/images_resize/profile4.png";
import mand5 from "../assets/images/images_resize/profile5.png";
import mand6 from "../assets/images/images_resize/profile6.png";

import vision from "../assets/images/Vision-min.png";
import mission from "../assets/images/Mission-min.png";
import excellence from "../assets/images/excellence-min.png";
import Integrity from "../assets/images/Integrity-min.png";
import Partnership from "../assets/images/Partnership-min.png";
import passion from "../assets/images/passion-min.png";

import july2017 from "../assets/images/2017 july 1.jpg";
import jan2018 from "../assets/images/2018 january 2.jpg";

import feb2019 from "../assets/images/2019 february 2.jpg";
import april2019 from "../assets/images/2019 april 1.jpg";
import dec2018 from "../assets/images/2018 december 2.jpg";






import jan19 from "../assets/images/January-2019.png";
import jan18 from "../assets/images/January-2018.png";
import july17 from "../assets/images/July-2017.png";
import nov17 from "../assets/images/November-2017.png";
import aug18 from "../assets/images/August-2018.png";



// import passion from "../assets/images/passion-min.png";


const About = () => {
    return (
        <div>
            <Header />
            <div>
                <div className="modal fade MuhammadYousufFazal" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ zIndex: 9999 }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body director">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 p-0">
                                            <img src={mand6} alt="" className="img-fluid rounded" />
                                        </div>
                                        <div className="col-7 pl-2 pr-2 m-auto d-block">
                                            <div className="mb-3">
                                                <h1 className="mb-0">Muhammad Yousuf Fazal</h1>
                                                <p className="mb-0">Head of Information Technology</p>
                                            </div>
                                            <div>
                                                Muhammad Yousuf Fazal is currently leading the Information Technology. He previously
                                                has a vast experience of developing and maintaining modern applications for both
                                                local and international clients. He holds a Bachelors Degree in Computer Science
                                                from FAST-NUCES.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade SyedMohsinAli" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ zIndex: 9999 }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body director">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 p-0">
                                            <img src={mand2} alt="" className="img-fluid rounded" />
                                        </div>
                                        <div className="col-7 pl-2 pr-2 m-auto d-block">
                                            <div className="mb-3">
                                                <h1 className="mb-0">Syed Mohsin Ali</h1>
                                                <p className="mb-0">Head of Operations</p>
                                            </div>
                                            <div>
                                                Syed Mohsin Ali is currently heading the Operations at Do Deliver. He has a vast
                                                experience in logistics&amp; e-commerce of thirteen years, lastly heading e-commerce
                                                Network Operations in M&amp;P Express Logistics.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade FawadAhmed" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ zIndex: 9999 }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body director">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 p-0">
                                            <img src={mand1} alt="" className="img-fluid rounded" />
                                        </div>
                                        <div className="col-7 pl-2 pr-2 m-auto d-block">
                                            <div className="mb-3">
                                                <h1 className="mb-0">Fawad Ahmed</h1>
                                                <p className="mb-0">Head of Human Resources &amp; Internal Audit</p>
                                            </div>
                                            <div>
                                                Fawad Ahmed He is currently leading Do Deliver as Company Secretary, and Head of Human
                                                Resources &amp; Internal Audit. He started his career from BlueEx as finance
                                                executive and lastly held the position of Finance Manager. Having experience of a
                                                decade in e-commerce industry, he has a Master in Business Administration with
                                                majors in Finance from Iqra University.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade WaqasAhmedDar" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ zIndex: 9999 }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body director">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 p-0">
                                            <img src={mand5} alt="" className="img-fluid rounded" />
                                        </div>
                                        <div className="col-7 pl-2 pr-2 m-auto d-block">
                                            <div className="mb-3">
                                                <h1 className="mb-0">Waqas Ahmed Dar</h1>
                                                <p className="mb-0">Head of Sales &amp; Business Development</p>
                                            </div>
                                            <div>
                                                Waqas Ahmed Dar is currently working in Do Deliver as Head of Sales &amp; Business
                                                Development. A well-known veteran in the ecommerce industry, he has led Sales teams
                                                at Leopards Couriers, Call Courier, and BlueEx. He has a Masters in Business
                                                Administration with majors in Finance from KASBIT.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade NomanAziz" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ zIndex: 9999 }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body director">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 p-0">
                                            <img src={mand3} alt="" className="img-fluid rounded" />
                                        </div>
                                        <div className="col-7 pl-2 pr-2 m-auto d-block">
                                            <div className="mb-3">
                                                <h1 className="mb-0">Noman Aziz</h1>
                                                <p className="mb-0">Head of Special Projects</p>
                                            </div>
                                            <div>
                                                Noman Aziz is currently serving Do Deliver as Head of Special Projects. He has a Master's
                                                in Business Administration from Iqra University and has a vast experience in
                                                e-commerce industry, having previously served TCS Ecom (Pvt.) Ltd. as Assistant
                                                Manager Customer Services.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade RahatAli" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center p-0">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ zIndex: 9999 }}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body director">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-4 p-0">
                                            <img src={mand4} alt="" className="img-fluid rounded" />
                                        </div>
                                        <div className="col-7 pl-2 pr-2 m-auto d-block">
                                            <div className="mb-3">
                                                <h1 className="mb-0">Rahat Ali</h1>
                                                <p className="mb-0">Head of Administration &amp; Security</p>
                                            </div>
                                            <div>
                                                Rahat Ali is currently heading the Administration Department &amp; Security at Do Deliver.
                                                He has a practical understanding of business needs and is highly effective at
                                                incorporating creative leadership skills to achieve business goals. A supportive and
                                                encouraging person to his team members.
                                                He has diversified experience in well-known organizations including K-Electric,
                                                First Paramount Modarba, and Colgate &amp; Palmolive Pakistan. Rahat Ali holds a Masters
                                                degree in HR Management.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="inner-slider" style="background:#ebebeb; padding:30px; width:100%;">
		<div class="container text-center">
			<h2 class="text-black">ABOUT US</h2>
		 </div>
	</div> */}
                <div className="container">
                    <section className="parallax pt-5 pb-5">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ padding: '2%' }}>
                                <div className="container" data-aos="fade-left">
                                    <h2>ABOUT Do Deliver</h2>
                                    <p>Do Deliver, an expanding name in the logistics and cargo industry that mainly deals in wholesale riders’ services and COD (cash on delivery) facilities. Syed Saad Ali and Syed Osama Ali are the co-founders of Do Deliver since 2017. Syed Saad Ali and Syed Osama Ali are the enthusiastic, passionate and dynamic directors of Do Deliver, the upcoming largest delivery organization in Pakistan.</p>
                                    <p>We do not just deliver goods, we deliver joys to our customers. We give hope to those who survived after natural disasters. Clients rely on a variety of transportation, e-commerce and business solutions. Our network covers all over Pakistan, providing over 99% of global GDP.</p>
                                    <p>Do Deliver focuses on the integrity and need of clients, seeing their needs and requirements is the foreseen attribute that lurks on successful journeys to reaching the pivot points of business. The most important thing in our value proposition is the close customer service culture that connects our team. Our employees focus on finding new ways to help our clients succeed and continuously improve their efficiency.</p>
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                            <div data-aos="fade-right" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-first order-sm-last" style={{ margin: '0px', padding: '0px' }}>
                                <img  src={Aboutus03} className="img-fluid" />
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div data-aos="fade-left" className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ margin: '0px', padding: '0px' }}>
                                <img src={Aboutus07} className="img-fluid" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ padding: '2%' }}>
                                <div className="container" data-aos="fade-right">
                                    <h2>ABOUT THE GROUP</h2>
                                    <p>Our relationship with customers is the spirit of our business. However, we will never forget that our company is also responsible for many other stakeholders: investors, employees, couriers, business partners, local communities and the global community.</p>
                                    <p>“Challenging to profusely accommodate deliverables within 24-hour’s time without complicated dynamics through dedicated riders at doorstep.”</p>
                                    <p>Do Deliver, a rising name in the logistics and cargo industry that mainly deals in wholesale riders’ services and COD (cash on delivery) facilities. Syed Saad Ali and Syed Osama Ali are the co-founders and CEO respectively since 2017. Syed Saad Ali and Syed Osama Ali are the enthusiastic, passionate and dynamic CEO of Do Deliver, the upcoming largest delivery organization in Pakistan. They believe in empowering teams and relies on teamwork to achieve their goals. It also supports an open, non-bureaucratic culture and makes interaction with all departments of the organization important. This is believed to be the most effective way to find long-term solutions and improve employee morale by finding an accessible approach.</p>
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <div class="leadership-section pt-5 pb-5">
		<div class="container clearfix">
			<div class="heading-block center">
				<div class="col-md-12">
					<h3>LEADERSHIP</h3>
				</div>
			</div>

			<div class="row topmargin-sm justify-content-center">
				<div class="col-md-3 col-sm-6 bottommargin" data-toggle="modal" data-target=".MuhammadHassanKhan">
					<div class="team hovereffect">
						<div class="team-image">
							<img src="https://Do Deliver.pk/images/3.png" alt="Muhammad Hassan Khan">
							<div class="overlay"></div>
						</div>
						<div class="team-desc team-desc-bg">
							<div class="team-title"><h4>Muhammad Hassan Khan</h4><span>Chief Executive Officer</span></div>
						</div>
					</div>
				</div>

				<div class="col-md-3 col-sm-6 bottommargin" data-toggle="modal" data-target=".AsadAbdulla">
					<div class="team hovereffect">
						<div class="team-image">
							<img src="https://Do Deliver.pk/images/4.png" alt="Asad Abdulla">
							<div class="overlay"></div>
						</div>
						<div class="team-desc team-desc-bg">
							<div class="team-title"><h4>Asad Abdulla</h4><span>Director</span></div>
						</div>
					</div>
				</div>

				<div class="col-md-3 col-sm-6 bottommargin" data-toggle="modal" data-target=".AyazAbdulla">
					<div class="team hovereffect">
						<div class="team-image">
							<img src="https://Do Deliver.pk/images/Do Deliverimages/leadership/AyazAbdulla.jpg" alt="Ayaz Abdulla">
							<div class="overlay"></div>
						</div>
						<div class="team-desc team-desc-bg">
							<div class="team-title"><h4>Ayaz Abdulla</h4><span>Director</span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> */}
                {/* <div className="ourheros-section">
                    <div className="container clearfix">
                        <div className="heading-block center">
                            <div className="col-md-12 pt-5">
                                <h3>OUR HEROES</h3>
                            </div>
                        </div>
                        <div className="row topmargin-sm pb-5">
                            <div className="col-md-2 col-sm-4 mb-4 mb-md-0" data-toggle="modal" data-target=".MuhammadYousufFazal">
                                <div className="team hovereffect">
                                    <div className="team-image">
                                        <img src={mand6} alt="Muhammad Yousuf Fazal" />
                                        <div className="overlay" />
                                    </div>
                                    <div className="team-desc team-desc-bg">
                                        <div className="team-title">
                                            <h4>Muhammad Yousuf Fazal</h4><span>Head of Information Technology</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 mb-4 mb-md-0" data-toggle="modal" data-target=".SyedMohsinAli">
                                <div className="team hovereffect">
                                    <div className="team-image">
                                        <img src={mand2} alt="Syed Mohsin Ali" />
                                        <div className="overlay" />
                                    </div>
                                    <div className="team-desc team-desc-bg">
                                        <div className="team-title">
                                            <h4>Syed Mohsin Ali</h4><span>Head of Operations</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 mb-4 mb-md-0" data-toggle="modal" data-target=".FawadAhmed">
                                <div className="team hovereffect">
                                    <div className="team-image">
                                        <img src={mand1} alt="Fawad Ahmed" />
                                        <div className="overlay">
                                        </div>
                                    </div>
                                    <div className="team-desc team-desc-bg">
                                        <div className="team-title">
                                            <h4>Fawad Ahmed</h4><span>Head of Human Resources &amp; Internal Audit</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 mb-4 mb-md-0" data-toggle="modal" data-target=".WaqasAhmedDar">
                                <div className="team hovereffect">
                                    <div className="team-image">
                                        <img src={mand5} alt="Waqas Ahmed Dar" />
                                        <div className="overlay" />
                                    </div>
                                    <div className="team-desc team-desc-bg">
                                        <div className="team-title">
                                            <h4>Waqas Ahmed Dar</h4><span>Head of Sales &amp; Business Development</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 mb-4 mb-md-0" data-toggle="modal" data-target=".NomanAziz">
                                <div className="team hovereffect">
                                    <div className="team-image">
                                        <img src={mand3} alt="Noman Aziz" />
                                        <div className="overlay" />
                                    </div>
                                    <div className="team-desc team-desc-bg">
                                        <div className="team-title">
                                            <h4>Noman Aziz</h4><span>Head of Customer Experience</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-4 mb-4 mb-md-0" data-toggle="modal" data-target=".RahatAli">
                                <div className="team hovereffect">
                                    <div className="team-image">
                                        <img src={mand4} alt="Rahat Ali" />
                                        <div className="overlay" />
                                    </div>
                                    <div className="team-desc team-desc-bg">
                                        <div className="team-title">
                                            <h4>Rahat Ali</h4><span>Head of Administration &amp; Security</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="CORPORATE-section">
                    <div className="container clearfix">
                        <div className="heading-block center">
                            <div className="col-md-12">
                                <h1>CORPORATE STRATEGY</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="heading-block center">
                                    <p className="text-center"><img style={{width:300}} src={vision} className="img-responsive" /></p>
                                    <h3>VISION</h3>
                                </div>
                                <p className="center">Providing the most innovative digital and logistical solutions in order to promote and strengthen Pakistan's E-Commerce business.</p>
                            </div>
                            <div className="col-md-6">
                                <div className="heading-block center">
                                    <p className="text-center"><img style={{width:300}} src={mission} className="img-responsive" /></p>
                                    <h3>MISSION</h3>
                                </div>
                                <p className="center">Taking on the challenge to profusely
                                    accommodate deliverables within 24
                                    hours, without any complications with
                                    our dedicated team of riders</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="heading-block center">
                                <h3>VALUES</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-center">
                                    <img style={{width:300}} src={excellence} className="img-responsive" />
                                    <h3>Excellence</h3>
                                    <p>Dedicated to achieving higher levels of performance and generating long-term outcomes.
                                    </p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img style={{width:300}} src={Integrity} className="img-responsive" />
                                    <h3>Integrity</h3>
                                    <p>Culture of openness, with an attitude of information sharing and learning.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-center">
                                    <img style={{width:300}} src={Partnership} className="img-responsive" />
                                    <h3>Partnership:</h3>
                                    <p>Choosing a course that will allow us to win as a team.</p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img style={{width:300}} src={passion} className="img-responsive" />
                                    <h3>Passion:</h3>
                                    <p>Stepping up to the plate &amp; showing full possession for results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="JourneySection" style={{ background: '#000' }}>
                    <div className="container">
                        <div className="wrapper">
                            <div className="row map-sec">
                                <div className="col-md-6 order-2 order-md-1">
                                    <div className="timeline ml-5 ml-md-0">
                                        <div className="line text-muted" />
                                        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div className="card card-default">
                                                <div className="card-header" role="tab" id="heading2017">
                                                    <div className="icon"> <a data-journey-id={3} role="button" className="timeLineHrefClass imageCollapse" id="timeLineHrefId2017" data-toggle="collapse" href="#collapse2017" aria-expanded="true" aria-controls="collapse2017"><i><span className="sr-only">Expand/Collapse</span></i></a>
                                                    </div>
                                                    <h4 className="card-title">2017</h4>
                                                </div>
                                                <div id="collapse2017" className="panel-collapse collapse show" aria-labelledby="heading2017" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p className="white-color "><b className="red-color">July: </b>Commencement of
                                                            operations on July 24 with
                                                            200 destinations</p>
                                                        <img src={july2017} alt="2017 July" className="img-fluid" style={{ maxWidth: '250px' }} />
                                                    </div>
                                                    <div className="card-body last">
                                                        <p data-journey-id={4} className="white-color imageCollapse" style={{ cursor: 'pointer' }}> <b className="red-color">November: </b>
                                                        </p>
                                                        <ul>
                                                            <li>Expanded to 250 destinations</li>
                                                            <li>First 100 customers Onboard</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-default">
                                                <div className="card-header" role="tab" id="heading2018">
                                                    <div className="icon"> <a data-journey-id={2} role="button" className="timeLineHrefClass collapsed imageCollapse" id="timeLineHrefId2018" data-toggle="collapse" href="#collapse2018" aria-expanded="false" aria-controls="collapse2018"><i><span className="sr-only">Expand/Collapse</span></i></a>
                                                    </div>
                                                    <h4 className="card-title">2018</h4>
                                                </div>
                                                <div id="collapse2018" className="panel-collapse collapse" aria-labelledby="heading2018" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">January: </b>
                                                        </p>
                                                        <ul>
                                                            <li>Expanded to 300 destinations</li>
                                                            <li>First Supply Chain route launch</li>
                                                        </ul>
                                                        <img src="jan2018" style={{ maxWidth: '250px' }} />
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">June: </b>Serving a clientele of
                                                            500+ customers</p>
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">July: </b>
                                                        </p>
                                                        <ul>
                                                            <li>Celebrated 1 st year of Launch</li>
                                                            <li>aiming to improve and digitize operational processes</li>
                                                        </ul>
                                                        <img src="images/2018%20july.jpg" style={{ maxWidth: '250px' }} />
                                                    </div>
                                                    <div className="card-body">
                                                        <p data-journey-id={5} className="white-color imageCollapse" style={{ cursor: 'pointer' }}><b className="red-color">August: </b>Expanded to 320
                                                            destinations</p>
                                                        <p className="white-color"><b className="red-color">October: </b>Launched SONIC,
                                                            with a revamped customer
                                                            and admin portal
                                                            <img src="images/2018%20october.jpg" alt="" className="img-fluid" style={{ maxWidth: '250px' }} />
                                                        </p>
                                                    </div>
                                                    <div className="card-body ">
                                                        <p className="white-color"><b className="red-color">December: </b>half million
                                                            shipments fulfilled</p>
                                                        <img src={dec2018} style={{ maxWidth: '250px' }} />
                                                    </div>
                                                    <div className="card-body last">
                                                        <p className="white-color"><b className="red-color">November: </b>Commencement of
                                                            Call Center Services
                                                            and Fulfilment Services</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-default">
                                                <div className="card-header" role="tab" id="heading2019">
                                                    <div className="icon"> <a data-journey-id={1} role="button" className="timeLineHrefClass collapsed imageCollapse" id="timeLineHrefId2019" data-toggle="collapse" href="#collapse2019" aria-expanded="false" aria-controls="collapse2019"><i><span className="sr-only">Expand/Collapse</span></i></a>
                                                    </div>
                                                    <h4 className="card-title">2019</h4>
                                                </div>
                                                <div id="collapse2019" className="panel-collapse collapse" aria-labelledby="heading2019" data-parent="#accordion">
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">January: </b>Launched Supply
                                                            Chain Routes
                                                            <img src="images/2019%20january%202.jpg" className="img-fluid" style={{ maxWidth: '250px' }} />
                                                        </p>
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">February: </b>
                                                        </p>
                                                        <ul>
                                                            <li>Launch of Walk-in Bookings services</li>
                                                            <li>Commencement of Overland/ Bulk movements</li>
                                                        </ul>
                                                        <img src={feb2019} className="img-fluid" style={{ maxWidth: '250px' }} />
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">April: </b>
                                                        </p>
                                                        <ul>
                                                            <li>Launched Delivery Intercept service</li>
                                                            <li>Serving a clientele of 1000+ customers</li>
                                                        </ul>
                                                        <img src={april2019} style={{ maxWidth: '250px' }} />
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="white-color"><b className="red-color">May: </b>
                                                        </p>
                                                        <ul>
                                                            <li>Started Agricultural Operations (Mango Deliveries)</li>
                                                            <li>Conducted special delivery project for Zameen.com</li>
                                                            <li>Launched Customer Relationship Management application to digitize
                                                                customer
                                                                experience</li>
                                                        </ul>
                                                    </div>
                                                    <div className="card-body last">
                                                        <p className="white-color"><b className="red-color">July: </b>Celebrated 2 nd year
                                                            of Launch, aiming
                                                            to bring new innovations in e-commerce logistics</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 order-1 order-md-2">
                                    <div className="map-container">
                                        <div id="img1" className="d-block">
                                            <img src={jan19} alt="January-2019" className="img-fluid img1 journey" id="journey_1" style={{ display: 'none' }} />
                                        </div>
                                        <div id="img2" className="d-block">
                                            <img src={jan18} alt="January-2018" className="img-fluid  img2 journey" id="journey_2" style={{ display: 'none' }} />
                                        </div>
                                        <div id="img3" className="d-block">
                                            <img src={july17} alt="July-2017" className="img-fluid  img3 journey" id="journey_3" style={{ display: 'inline' }} />
                                        </div>
                                        <div id="img4" className="d-block">
                                            <img src={nov17} alt="November-2017" className="img-fluid  img4 journey" id="journey_4" style={{ display: 'none' }} />
                                        </div>
                                        <div id="img5" className="d-block">
                                            <img src={aug18} alt="August-2018" className="img-fluid  img5 journey" id="journey_5" style={{ display: 'none' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="clearfix" />
            </div>

            <Footer />
        </div>
    )
}
export default About