/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Footer from '../component/Footer';
import Header from '../component/Header';
import { Card } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useNavigate, useParams } from "react-router-dom"
import { useState, useEffect } from 'react'
import baseUrl from "../config/baseurl"
import Swal from 'sweetalert2'
import {
  Timeline,
  Container,
  YearContent,
  BodyContent,
  Section,
  Description,
} from 'vertical-timeline-component-react';
// import SchoolIcon from "@material-ui/icons/School";
// import WorkIcon from "@material-ui/icons/Work";
// import StarIcon from "@material-ui/icons/Star";

const Tracksearch = () => {

  const params = useParams()
  const Navigate = useNavigate()
  console.log("params", params.trackNumber)


  const [data, setData] = useState("")
  const [datas, setDatas] = useState([])
  const [det, setdet] = useState([])
  const customTheme = {
    yearColor: '#405b73',
    lineColor: '#d0cdc4',
    dotColor: '#262626',
    borderDotColor: '#d0cdc4',
    titleColor: '#405b73',
    subtitleColor: '#bf9765',
    textColor: '#262626',
  };



  useEffect(() => {
    tracking();
  }, [params.trackNumber])


  const tracking = () => {

    var requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    };
    fetch(`${baseUrl.baseUrl}api/order/status/website/` + params.trackNumber.toString())
      .then((result) => {
        result.json().then((resp) => {


          // let abc = estimated_delivery_time

          // console.log("order time in detail object ==>",resp ['order_detail'] ['order_time'] )
          // console.log("name in detail object ==>",resp ['order_detail'] ['name'] )
          console.log("this is resp==>", resp)
          // console.log("timeline object is ==>", resp.status_dtails.map((x) => x))

          // first object ko yahan  save kia
          // let detail = resp.status_dtails.map((x) => x);

          // timeline => do date hai [26jan ,89feb]
          let detail = resp.timeline.map((x) => x);

          console.log('detail', detail.map(x => x.status));


          // let text = resp['order_detail']['order_time']
          // let result = text.slice(10, 20);
          // console.log(result,"result")
          // secoond wale is me kia hai
          console.log("this is error message", resp['status'])

          let lock = detail.map(x => x.status.map(m => m.time))

          console.log(lock, "lock")
          setdet(detail)
          setData(resp['order_detail'])

          // } else {
          //   Swal.fire({
          //     title: "Oops",
          //     text: resp.message,
          //     icon: "error",
          //     confirmButtonColor: "#ed2a26",
          //   });
          //   Navigate("/");
          // }




        })
      })
  }
  console.log(data.city)


  const trackingStatus = () => {

    var requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    };
    fetch(`${baseUrl.baseUrl}api/orders-status/website/` + params.trackNumber.toString())
      .then((result) => {
        result.json().then((resp) => {
          console.log("this is resp==>=", resp)
          let details = resp.timeline.map((x) => x);
          setDatas(details)

        })
      })
  }
console.log(datas,'setDatas')
  useEffect(() => {
    trackingStatus();
  }, []);

  return (
    <div>
      <Header />
      <div>
        <section>
          <div className="section nobottommargin nobottomborder" style={{ backgroundColor: '#f5f5f5' }}>
            <div className="container clearfix">
              <div className="heading-block center nomargin">
                <div className="container-fluid pb-5 clients-logo">
                  <div className="row no-gutters justify-content-center text-center">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="p-2 p-md-5 border-logo">
                        <Card className='cardcss' >
                          <Card.Header as="h5" style={{ color: "#fff" }} >Recipient</Card.Header>
                          <Card.Body>
                            {/* <Card.Title>Recipient</Card.Title> */}
                            {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                            {/* <Card.Text>
                            Some quick example text to build on the card title and make up the bulk of
                            the card's content.
                          </Card.Text> */}

                            <div className='row'>
                              <div className='col-md-6 mb-4 '>


                                {/* <span>Name</span> &nbsp; &nbsp;&nbsp;  <label>{data.name}</label> */}
                                <span><h4>Name</h4></span>  <label>{data.name}</label>
                              </div>
                              <div className='col-md-6 mb-4'>

                                <span><h4>Price Destination Area</h4></span>  <label>{data.city}</label>
                              </div>
                              <div className='col-md-6 mb-4'>

                                <span><h4>Phone</h4></span>  <label>{data.phone}</label>
                              </div>
                              <div className='col-md-6 mb-4'>
                                <h4>Routing Destination Area</h4> <label>{data.area}</label>
                              </div>
                              <div className='col-md-6 mb-4'>

                                <span> <h4>Email</h4></span>  <label>{data.email}</label>
                              </div>
                              <div className='col-md-6 mb-4'>
                                <h4>Address</h4> <label>{data.delivery_address}</label>
                              </div>
                              <div className='col-md-6 mb-4'>
                                <h4>Received by</h4>
                              </div>
                              <div className='col-md-6 mb-4'>
                                <h4>Advice</h4> <label>{data.additional_info}</label>

                              </div>

                            </div>

                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="p-2 p-md-5 border-logo">
                        {/* {
                          det.map((x) =>

                            <Timeline theme={customTheme} dateFormat='ll'>
                              <Container>
                                <YearContent startDate={x.date} currentYear />
                                <BodyContent>
                                  <h4 className='pb-4' >{x.date}</h4>
                                  {x.status.map((m) =>
                                    <Section title={m.time}>
                                      <Description text={m.name} />
                                      <Description text={m.log} />
                                    </Section>
                                  )}
                                </BodyContent>
                              </Container>
                            </Timeline>
                          )
                        } */}
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Name</th>
                              <th scope="col">Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {datas.map((x) =>
                            <tr>
                              <td>{x.date}</td>
                              <td>{x.name}</td>
                              <td>{x.time}</td>
                            </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix" />
      </div>
      <Footer />
    </div>
  )
};

export default Tracksearch;
