import React from "react";
import { Link } from 'react-router-dom'
import Header from "../component/Header";
import Footer from "../component/Footer";
import career3 from '../assets/images/Careers_03.png'
import career7 from '../assets/images/Careers_07.png'

import it from '../assets/images/Information Technology-min.png'
import operation from '../assets/images/Operations-min.png'
import payment from '../assets/images/Fastest Payments.png'
import business from '../assets/images/Business Development-min.png'
import customer from '../assets/images/Customer Experience-min.png'

import humanresource from '../assets/images/Human Resources and Administration-min.png'






const Career = () => {
    return (
        <div>
            <Header />

            <div>
                <div className="clearfix" />
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div data-aos="fade-left" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                            <img src={career3} className="img-fluid" />
                        </div>
                        <div data-aos="fade-right" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                            <div className="container culture">
                                <h2>Culture</h2>
                                <p>The entire culture of Do Deliver revolves around our vision "To be seen as enablers of E-Commerce in Pakistan". To ensure that this vision is shared by all the teams working across Pakistan, we live and work by the following code:</p>
                                <ul>
                                    <li>
                                        Teams/Employees are the top priority
                                    </li>
                                    <li>
                                        Innovation Comes First - "TechOps"
                                    </li>
                                    <li>
                                        Involvement and collaboration is an essential element of our work routine
                                    </li>
                                </ul>
                                <p>Do Deliver provides a culture of trust, accountability, respect and collaboration, where we ensure everyone strives to work for the organization's transformation.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div data-aos="fade-left" className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0 text-center">
                            <img src={career7} className="img-responsive" />
                        </div>
                        <div data-aos="fade-right" className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                            <div className="container">
                                <h2>Department</h2>
                                <p>Do Deliver is proud to work with the best people having the expertise in the following domains:</p>
                                <div className="row">
                                    <div className="col-md-6 text-center">
                                        <img src={it} className="img-responsive" />
                                        <h3>Information Technology</h3>
                                        <p>Developing innovative solutions of the present and the future, our technology team ensures a seamless digital experience for our users.</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={operation} className="img-responsive" />
                                        <h3>Operations</h3>
                                        <p>The backbone of Do Deliver, managing and operating first mile, supply chain and last mile functions across Pakistan.</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={payment} className="img-responsive" style={{ maxWidth: '250px' }} />
                                        <h3>Finance</h3>
                                        <p>Managing the cash flows, expenses and ensuring our biggest USP of paying back the customers within 24 hours.</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={business} className="img-responsive" />
                                        <h3>Sales and Business Development</h3>
                                        <p>Building &amp; growing relationships with thousands of e-commerce businesses, our sales and business development team offers turnkey solutions for the industry.</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={customer} className="img-responsive" />
                                        <h3>Customer Experience</h3>
                                        <p>From expediting requests to resolving problems and complaints, our CX team provides excellence in the support to our customers and their customers.</p>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <img src={humanresource} className="img-responsive" />
                                        <h3>Human Resources and Administration</h3>
                                        <p>Keeping our offices running smoothly &amp; our people happy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix" />
            </div>



            <Footer />
        </div>
    )
}
export default Career