import React from 'react';
import Footer from '../component/Footer';
import Header from '../component/Header';


const Termscondition = () => {
    return (

        <div>
            {/* <h1>baloch</h1> */}
            <Header />
            <div>

                <div className="container">
                    <section className="parallax pt-5 pb-5">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0%' }}>
                                <div className="container" data-aos="fade-left">
                                    <h2 className='text-center h1-head' style={{ color: "e90303" }} >TERMS AND CONDITIONS OF CARRIAGE</h2>
                                    {/* <h2 className='text-center' style={{color:"e90303"}} >Terms and Conditions</h2> */}
                                    <h2 className='text-center mt-5' style={{}} >IMPORTANT NOTICE</h2>
                                    <p className='mt-2 ' >
                                        When ordering Do deliver services you, as "Shipper", are agreeing, on your behalf and on behalf of anyone else with an interest in the Shipment that the Terms and Conditions shall apply from the time that Do deliver accepts the Shipment unless otherwise agreed in writing by an authorized officer of Do deliver. Your statutory rights and entitlements under any defined service feature (for which additional payment has been made) are not affected. "Shipment" means all documents or parcels that travel under one waybill and may be carried by any means Do deliver chooses, including air, road or any other carrier. A "waybill" shall include any label produced by Do deliver‘s automated systems, air waybill, or consignment note and shall incorporate these Terms and Conditions. Every Shipment is transported on a limited liability basis as provided herein. If Shipper requires greater protection, then Shipment Value Protection may be arranged at an additional cost. (Please see below for further information). "Do deliver" means any member of the UNS Network. This is the ephemeral version of "Standard Terms & Conditions of Carriage". The detailed version of the same is available on www.blue-ex.com or for physical copy please contact your concern sales representative.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="row align-items-center justify-content-center">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0%' }}>
                                <div className="container" data-aos="fade-left">
                                    <h2  className='h2-heading'>1.	Customs, Exports and Imports</h2>
                                    <p>Do deliver may perform any of the following activities on Shipper's behalf in order to provide its services to Shipper: (1) complete and transmit (manually or electronically) any documents, amend product or service codes, and pay any duties or taxes required under applicable laws and regulations, (2) act as Shipper's forwarding agent for customs and export control purposes and as Receiver solely for the purpose of designating a customs broker to perform customs clearance and entry and (3) redirect the Shipment to Receiver's import broker or other address upon request by any person who Do deliver believes in its reasonable opinion to be authorized.</p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0%' }}>
                                <div className="container" data-aos="fade-left">
                                    <h2 className='h2-heading' >2. Unacceptable Shipments</h2>
                                    <p>
                                        <p> Shipper agrees that its Shipment is acceptable for transportation and is deemed unacceptable if:</p>

                                        <p> a) It is classified as hazardous material, dangerous goods, prohibited or restricted articles by IATA (International Air Transport Association), ICAO (International Civil Aviation Organization), any applicable government department or other relevant organization. </p>
                                        <p> b) No customs declaration is made when required by applicable customs regulations or</p>
                                        <p> c) Do deliver decides it cannot transport an item safely or legally (such items include but are not limited to: animals, bullion, currency, bearer form negotiable instruments, precious metals and stones, firearms, parts thereof and ammunition, human remains, pornography and illegal narcotics/drugs). </p>
                                    </p>



                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0%' }}>
                                <div className="container" data-aos="fade-left">
                                    <h2  className='h2-heading'>3.	Deliveries & Un-deliverables</h2>
                                    <p>Shipments cannot be delivered to PO boxes or postal codes. Shipments are delivered to the Receiver's address given by Shipper (which in the case of mail services shall be deemed to be the first receiving postal service) but not necessarily to the named Receiver personally. Shipments to addresses with a central receiving area will be delivered to that area. If Receiver refuses delivery or to pay for delivery, or the Shipment is deemed to be unacceptable, or it has been undervalued for customs purposes, or Receiver cannot be reasonably identified or located, Do deliver shall use reasonable efforts to return the Shipment to Shipper at Shipper's cost, failing which the Shipment may be released, disposed of or sold by Do deliver without incurring any liability whatsoever to Shipper or anyone else, with the proceeds applied against service charges and related administrative costs and the balance of the proceeds of a sale to be returned to Shipper.</p>



                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0%' }}>
                                <div className="container" data-aos="fade-left">
                                    <h2  className='h2-heading'>4.	Inspection & Liability</h2>
                                    <p>Do deliver has the right to open and inspect a Shipment without prior notice to Shipper. The liability of the Blue-Ex is limited to the Terms & Conditions of Carriage as mentioned in standard terms & conditions of carriage as published on our website.                                                                 </p>



                                </div>
                            </div>

                        </div>
                        {/* <div className="row align-items-center justify-content-center">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '2%' }}>
                                <div className="container" data-aos="fade-right">
                                    <h2>ABOUT THE GROUP</h2>
                                    <p>Our relationship with customers is the spirit of our business. However, we will never forget that our company is also responsible for many other stakeholders: investors, employees, couriers, business partners, local communities and the global community.</p>
                                    <p>“Challenging to profusely accommodate deliverables within 24-hour’s time without complicated dynamics through dedicated riders at doorstep.”</p>
                                    <p>Do Deliver, a rising name in the logistics and cargo industry that mainly deals in wholesale riders’ services and COD (cash on delivery) facilities. Syed Saad Ali and Syed Osama Ali are the co-founders and CEO respectively since 2017. Syed Saad Ali and Syed Osama Ali are the enthusiastic, passionate and dynamic CEO of Do Deliver, the upcoming largest delivery organization in Pakistan. They believe in empowering teams and relies on teamwork to achieve their goals. It also supports an open, non-bureaucratic culture and makes interaction with all departments of the organization important. This is believed to be the most effective way to find long-term solutions and improve employee morale by finding an accessible approach.</p>
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                        </div> */}
                    </section>
                </div>

                <div className="clearfix" />
            </div>

            <Footer />
        </div>
    )
};

export default Termscondition;
