import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from './pages/Index'
import About from './pages/About';
import Ourservices from './pages/Ourservices';
import Pricing from './pages/Pricing';
import Career from './pages/Career';
import Termscondition from './pages/Termscondition';
import Tracksearch from './pages/Tracksearch';
// import Tracksearch from './pages/Tracksearch';
// import Termscondition from './pages/Termscondition'
// import Togle from './pages/Togle';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route exact path="/" element={<Index />}/>
        <Route exact path="about" element={<About />}/>
        <Route exact path="ourservices" element={<Ourservices />}/>
        <Route exact path="pricing" element={<Pricing />}/>
        <Route exact path="career" element={<Career />}/>
        <Route exact path="termsandcondition" element={<Termscondition />}/>
        <Route exact path="track/:trackNumber" element={<Tracksearch />}/>
        {/* <Route exact path="tracksearch" element={<Tracksearch />}/> */}
        {/* <Route exact path="togle" element={<Togle />}/> */}
      </Routes>
    </Router>
    </>
  );
}

export default App;
