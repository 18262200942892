import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/logo.png'
// import Togle from '../pages/Togle';

const Header = () => {
    const Navigate = useNavigate();
    const [btn, setBtn] = useState(false)
    const [togle, setTogle] = useState(true)
    const [TrackNumber, setTrackNumber] = useState("")
    

    // const operation = () => {
    //     console.log("helllo world")

    //     setBtn(!btn)
    // }

    const track = ()=>{
        Navigate(`/track/${TrackNumber}`)
        
        // Navigate({pathname:"/track/123"})
    }

    
// teser 

    return (
        <>
            <header id="header" className="transparent-header full-header fixed-top" data-sticky-class="not-dark">
                <div className="top-header p-2">
                    <div className="container">
                        <div className="row align-items-center">
                            <Link to="" className="col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 image">
                                <div className="logo"><img style={{ width: '50%' }} src={Logo} /></div>
                            </Link>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-10 text-right">
                                <ul className="top-social-icon">
                                    <li className="contact"><b>Contact us: </b><Link to="tel:+02133383990"><i className="fa fa-phone" aria-hidden="true" /> 021-33383990</Link></li>
                                    <li className="contact"><Link to="mailto:info@dodeliver.com.pk"><i className="fa fa-envelope-o" aria-hidden="true" /> info@dodeliver.com.pk</Link></li>
                                    <li className="btn brown-bg-button">
                                        {/* <Link to="https://dodeliver.hnhclient.xyz/" target="_blank">Sign
                                            In</Link> */}
                                            <a href="https://dodeliver.hnhtechsolutions.com" target="_blank">Sign
                                            In</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="top-navigation-bg p-2">
                    <div className="container">
                        <form onSubmit={track} noValidate="novalidate" id="track" className="float-left mb-0">
                            <div className="input-group"   >
                                {/* <input type="text" className="form-control d-none" placeholder="Enter Tracking Number" name="tracking_number" aria-describedby="track_icon" /> */}
                                <input type="text" onChange={(e)=>setTrackNumber(e.target.value)} className={btn == true?"form-control":"form-control d-none"} autoComplete='off'  placeholder="Enter Tracking Number" name="tracking_number" aria-describedby="track_icon" />
                                <div className="input-group-append" onClick={() => setBtn(!btn) }>
                                    <span className="input-group-text" id="track_icon">
                                        <i className={btn == true?"d-none":"fa fa-search"}  aria-hidden="true" />
                                        <i className={btn == false?"d-none":"fa fa-search"} onClick={track}  aria-hidden="true" />
                                        <span className={btn == true?"d-none":""} >Track</span>
                                    </span>
                                </div>
                            </div>
                        </form>
                        <nav className="navbar navbar-expand-lg navbar-light nopadding justify-content-end">
                            <button type="button" className="navbar-toggler border-0">
                                <span className="navbar-toggler-icon" onClick={() => { setTogle(!togle) }} />

                            </button>
                                          
                            {/* <div style={{ width: '250px' }} id="navbarSupportedContent" className ={togle ? "navbar-collapse justify-content-end sidenav-menu "  : null   }  > */}
                            {/* navbar togle  */}
                            <div className={togle ? "navbar-collapse justify-content-end sidenav-menu ab " : "navbar-collapse justify-content-end sidenav-menu abc"} id="navbarSupportedContent"   >
                                <Link to="" className="closebtn" onClick={() => { setTogle(!togle) }}   >×</Link>
                                <ul className="nav">
                                    <li className="nav-item">
                                        <NavLink className="nav-link " to="/">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " to="/ourservices">Services</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " to="/about">About Us</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " to="/pricing">Pricing</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " to="/career">Career</NavLink>
                                    </li>
                                    {/* <li className="nav-item">
                                        <NavLink className="nav-link " to="/ahmed">Tracksearch</NavLink>
                                    </li> */}
                                    {/* <li class="nav-item">
                                        <Link class="nav-link " to="https://DoDeliver.pk/blog">Blog</Link>
                                    </li> */}
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}
export default Header