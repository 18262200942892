import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import mand1 from "../assets/images/1mand.png";
import mand2 from "../assets/images/2mand.png";
import mand3 from "../assets/images/3mand.png";
import mand4 from "../assets/images/4mand.png";
import mand5 from "../assets/images/5mand.png";
import mand6 from "../assets/images/6mand.png";
import mand7 from "../assets/images/7mand.png";
import mand8 from "../assets/images/8mand.png";
import mand9 from "../assets/images/9mand.png";
import rc1 from "../assets/images/rc1.png";
import rc2 from "../assets/images/rc2.png";
import faqmin from "../assets/images/Faq-min.png";
import price10 from "../assets/images/Pricing_10.png";


const Pricing = () =>{
    return(
        <div>
            <Header />
            <div>
              <div className="form-section pb-5">
                <div className="container re-order">
                  <div className style={{width: '100%'}}>
                    <center className="mb-5 mt-5">
                      <h1>RATES CALCULATOR</h1>
                    </center>
                    <div className="row">
                      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                        <div className="border p-3">
                          <form id="rates_calculation_form">
                            <div>
                              <div className="row">
                                <div className="col-lg-4 form-group">
                                  <select className="form-control" id="account_type" name="account_type" data-rule-required="true" data-msg-required="Account Type is required">
                                    <option value>Account Type</option>
                                    <option value={1}>Cash on Delivery Account</option>
                                    <option value={2}>Corporate Invoicing Account</option>
                                  </select>
                                </div>
                                <div className="col-lg-4 form-group">
                                  <select className="form-control" id="shipping_mode" name="shipping_mode" data-rule-required="true" data-msg-required="Shipping Mode is required">
                                    <option value>Shipping Mode</option>
                                    <option value={1}>Overnight</option>
                                    <option value={2}>Overland</option>
                                    <option value={4}>Same-Day</option>
                                  </select>
                                </div>
                                <div className="col-lg-4 form-group">
                                  <select className="form-control" id="booking_type" name="booking_type" data-rule-required="true" data-msg-required="Booking Type is required">
                                    <option value>Service Type</option>
                                    <option value={1}>Regular</option>
                                    <option value={2}>Replacement</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <div className="col-lg-4 form-group">
                                  <select className="form-control" id="origin" name="origin" data-rule-required="true" data-msg-required="Origin is required">
                                    <option value>Origin</option>
                                    <option value={110}>Bahawalpur</option>
                                    <option value={144}>Faisalabad</option>
                                    <option value={158}>Gujranwala</option>
                                    <option value={159}>Gujrat</option>
                                    <option value={172}>Hyderabad</option>
                                    <option value={174}>Islamabad</option>
                                    <option value={186}>Jhelum</option>
                                    <option value={202}>Karachi</option>
                                    <option value={223}>Lahore</option>
                                    <option value={251}>Multan</option>
                                    <option value={271}>Peshawar</option>
                                    <option value={284}>Rahim Yar Khan</option>
                                    <option value={288}>Rawalpindi</option>
                                    <option value={293}>Sahiwal</option>
                                    <option value={302}>Sargodha</option>
                                    <option value={315}>Sialkot</option>
                                    <option value={318}>Sukkur</option>
                                  </select>
                                </div>
                                <div className="col-lg-4 form-group">
                                  <select className="form-control" id="destination" name="destination" data-rule-required="true" data-msg-required="Destination is required">
                                    <option value>Destination</option>
                                    <option value={101}>Abbottabad</option>
                                    <option value={102}>Abdul Hakim</option>
                                    <option value={103}>Ahmed Pur East</option>
                                    <option value={104}>Alipur</option>
                                    <option value={105}>Ali Pur Chatta</option>
                                    <option value={106}>Arifwala</option>
                                    <option value={107}>Attock</option>
                                    <option value={108}>Badin</option>
                                    <option value={109}>Bahawalnagar</option>
                                    <option value={110}>Bahawalpur</option>
                                    <option value={111}>Bannu</option>
                                    <option value={112}>Basir Pur</option>
                                    <option value={113}>Basti Shorkot</option>
                                    <option value={114}>Behra</option>
                                    <option value={115}>Bhagtanwala</option>
                                    <option value={116}>Bhakkar</option>
                                    <option value={117}>Bhalwal</option>
                                    <option value={119}>Burewala</option>
                                    <option value={121}>Chak Jhumra</option>
                                    <option value={122}>Chakwal</option>
                                    <option value={124}>Chenab Nagar</option>
                                    <option value={125}>Charsadda</option>
                                    <option value={126}>Chashma</option>
                                    <option value={127}>Chawinda</option>
                                    <option value={128}>Chicha watni</option>
                                    <option value={129}>Chiniot</option>
                                    <option value={130}>Chishtian</option>
                                    <option value={131}>Dadu</option>
                                    <option value={132}>Dahranwala</option>
                                    <option value={133}>Depalpur</option>
                                    <option value={134}>Dera Ghazi Khan</option>
                                    <option value={135}>Dera Ismail Khan</option>
                                    <option value={136}>Daharki</option>
                                    <option value={137}>Dijkot</option>
                                    <option value={138}>Dina</option>
                                    <option value={139}>Dinga</option>
                                    <option value={140}>Donga Bonga</option>
                                    <option value={141}>Dunyapur</option>
                                    <option value={142}>Daur</option>
                                    <option value={143}>Isakhel </option>
                                    <option value={144}>Faisalabad</option>
                                    <option value={145}>Faqir Wali</option>
                                    <option value={146}>Farooka</option>
                                    <option value={147}>Fatima Fertilizer Company</option>
                                    <option value={148}>Feroza</option>
                                    <option value={149}>Fort Abbas</option>
                                    <option value={150}>Gaggoo Mandi</option>
                                    <option value={151}>Gambat</option>
                                    <option value={152}>Ghotki</option>
                                    <option value={154}>Gojra</option>
                                    <option value={155}>Goth Machi</option>
                                    <option value={156}>Guddu</option>
                                    <option value={157}>Gujar Khan</option>
                                    <option value={158}>Gujranwala</option>
                                    <option value={159}>Gujrat</option>
                                    <option value={160}>Hadli</option>
                                    <option value={161}>Hafizabad</option>
                                    <option value={162}>Hala</option>
                                    <option value={164}>Harrapa</option>
                                    <option value={165}>Haripur</option>
                                    <option value={167}>Haroonabad</option>
                                    <option value={168}>Hasan Abdal</option>
                                    <option value={169}>Hasil Pur</option>
                                    <option value={170}>Haveli Lakha</option>
                                    <option value={171}>Hujra Shah Muqeem</option>
                                    <option value={172}>Hyderabad</option>
                                    <option value={173}>Iskanderabad</option>
                                    <option value={174}>Islamabad</option>
                                    <option value={175}>Jacobabad</option>
                                    <option value={176}>Jehangira</option>
                                    <option value={177}>Jahanian</option>
                                    <option value={178}>Jalal Pur</option>
                                    <option value={179}>Jalal Pur Jattan</option>
                                    <option value={180}>Jalalpur Pir Wala</option>
                                    <option value={181}>Jampur</option>
                                    <option value={182}>Jamshoro</option>
                                    <option value={183}>Jaranwala</option>
                                    <option value={184}>Jatoi</option>
                                    <option value={185}>Jauharabad</option>
                                    <option value={186}>Jhelum</option>
                                    <option value={187}>Jetha Bhutta</option>
                                    <option value={188}>Jhang</option>
                                    <option value={189}>Khairpur Nathan Shah</option>
                                    <option value={190}>Kabir Wala</option>
                                    <option value={191}>Kahror Pakka</option>
                                    <option value={192}>Kahuta</option>
                                    <option value={193}>Kala Bagh</option>
                                    <option value={194}>Kallar Kahar</option>
                                    <option value={195}>Kamaliya</option>
                                    <option value={196}>Kamar Mushani</option>
                                    <option value={197}>Kamir</option>
                                    <option value={198}>Kamoke </option>
                                    <option value={199}>Kamra</option>
                                    <option value={200}>Kandh Kot</option>
                                    <option value={201}>Kandiaro</option>
                                    <option value={202}>Karachi</option>
                                    <option value={203}>Kashmore</option>
                                    <option value={204}>Kasur</option>
                                    <option value={205}>Khairpur Mirs</option>
                                    <option value={206}>Khairpur Tamiwali</option>
                                    <option value={208}>Khan Pur</option>
                                    <option value={209}>Khanewal</option>
                                    <option value={210}>Kharian</option>
                                    <option value={212}>Khichi Wala</option>
                                    <option value={213}>Khurrianwala</option>
                                    <option value={214}>Khushab</option>
                                    <option value={215}>Kohat</option>
                                    <option value={216}>Kot Addu</option>
                                    <option value={218}>Kot Momin</option>
                                    <option value={219}>Kot Sabzal</option>
                                    <option value={220}>Kot Samaba</option>
                                    <option value={221}>Kotli</option>
                                    <option value={223}>Lahore</option>
                                    <option value={224}>Lalamusa</option>
                                    <option value={225}>Lalian</option>
                                    <option value={226}>Larkana</option>
                                    <option value={227}>Layyah</option>
                                    <option value={228}>Liaquat Pur</option>
                                    <option value={229}>Lodhran</option>
                                    <option value={231}>Luddan</option>
                                    <option value={232}>Machi Wal</option>
                                    <option value={233}>Mailsi</option>
                                    <option value={234}>Malka Hans</option>
                                    <option value={236}>Mandrah</option>
                                    <option value={237}>Mansehra</option>
                                    <option value={238}>Mardan</option>
                                    <option value={239}>Marot</option>
                                    <option value={240}>Mian Channu</option>
                                    <option value={241}>Mianwali</option>
                                    <option value={242}>Minchin Abad</option>
                                    <option value={243}>Mirpur Khas</option>
                                    <option value={244}>Mirpur Azad Kashmir </option>
                                    <option value={245}>Mirpur Mathelo</option>
                                    <option value={246}>Mithi</option>
                                    <option value={247}>Matiari </option>
                                    <option value={249}>Mitro</option>
                                    <option value={250}>Moro</option>
                                    <option value={251}>Multan</option>
                                    <option value={252}>Musafir Khana</option>
                                    <option value={254}>Muzaffar Garh</option>
                                    <option value={255}>Muzaffarabad </option>
                                    <option value={256}>Nankana Sahib</option>
                                    <option value={257}>Nawabshah</option>
                                    <option value={259}>Noor Pur Thal</option>
                                    <option value={260}>Noor Shah</option>
                                    <option value={263}>Naudero</option>
                                    <option value={264}>Nowshera</option>
                                    <option value={265}>Nowshera Virkan</option>
                                    <option value={266}>Naushahro Feroze</option>
                                    <option value={267}>Okara</option>
                                    <option value={268}>Okara Cantt</option>
                                    <option value={269}>Pakpattan</option>
                                    <option value={270}>Pano Akil</option>
                                    <option value={271}>Peshawar</option>
                                    <option value={272}>Pindi Bhatiyan</option>
                                    <option value={273}>Sarai Alamgir</option>
                                    <option value={274}>Piplan</option>
                                    <option value={275}>Pir Mahal</option>
                                    <option value={277}>Qaim Pur</option>
                                    <option value={278}>Qazi Ahmed</option>
                                    <option value={280}>Qila Didar Singh</option>
                                    <option value={282}>Quaidabad</option>
                                    <option value={283}>Quetta</option>
                                    <option value={284}>Rahim Yar Khan</option>
                                    <option value={285}>Rajan Pur</option>
                                    <option value={286}>Ranipur</option>
                                    <option value={287}>Rato Dero</option>
                                    <option value={288}>Rawalpindi</option>
                                    <option value={289}>Renala Khurd</option>
                                    <option value={290}>Risalpur</option>
                                    <option value={291}>Rohri</option>
                                    <option value={292}>Sadiqabad</option>
                                    <option value={293}>Sahiwal</option>
                                    <option value={296}>Sakhi Sarwar</option>
                                    <option value={297}>Sakrand</option>
                                    <option value={298}>Samundri</option>
                                    <option value={299}>Sanghar</option>
                                    <option value={300}>Sangla Hill</option>
                                    <option value={301}>Sanjar Pur</option>
                                    <option value={302}>Sargodha</option>
                                    <option value={303}>Satiana</option>
                                    <option value={304}>Swabi</option>
                                    <option value={305}>Sehwan</option>
                                    <option value={307}>Shahdara</option>
                                    <option value={308}>Shah Kot</option>
                                    <option value={309}>Shah Pur</option>
                                    <option value={310}>Shahdad Pur</option>
                                    <option value={311}>Sheikhupura</option>
                                    <option value={312}>Shikarpur</option>
                                    <option value={314}>Shuja Abad</option>
                                    <option value={315}>Sialkot</option>
                                    <option value={317}>Sillanwali</option>
                                    <option value={318}>Sukkur</option>
                                    <option value={319}>Swat</option>
                                    <option value={320}>Talagang</option>
                                    <option value={321}>Tandlianwala</option>
                                    <option value={322}>Tando Adam</option>
                                    <option value={323}>Tando Allah Yar</option>
                                    <option value={324}>Tando Jam</option>
                                    <option value={325}>Tando Muhammad Khan</option>
                                    <option value={327}>Taranda Muhammad Panah</option>
                                    <option value={328}>Taranda Saway Khan</option>
                                    <option value={329}>Tarbela</option>
                                    <option value={331}>Taunsa Sharif</option>
                                    <option value={332}>Taxila</option>
                                    <option value={334}>Tiba Sultan Pur</option>
                                    <option value={335}>Timergarah</option>
                                    <option value={336}>Toba Tek Singh</option>
                                    <option value={337}>Topi</option>
                                    <option value={338}>Uch Sharif</option>
                                    <option value={339}>Vehari</option>
                                    <option value={340}>Wah Cantt</option>
                                    <option value={341}>Wan Bhachran</option>
                                    <option value={342}>Wazirabad</option>
                                    <option value={343}>Yazman</option>
                                    <option value={344}>Zahir Pir</option>
                                    <option value={348}>Darya Khan</option>
                                    <option value={349}>Kot Chutta</option>
                                    <option value={350}>Fazilpur</option>
                                    <option value={351}>Paigah</option>
                                    <option value={352}>Hazro</option>
                                    <option value={353}>Gondal</option>
                                    <option value={354}>Hatiyan</option>
                                    <option value={355}>Akora Khattak</option>
                                    <option value={356}>Umerkot</option>
                                    <option value={357}>Kunri</option>
                                    <option value={358}>Mirwah</option>
                                    <option value={359}>Jamesabad</option>
                                    <option value={360}>Kot Ghulam Muhammad</option>
                                    <option value={361}>Islamkot</option>
                                    <option value={363}>Jhuddo</option>
                                    <option value={364}>Tando Jan Mohammad</option>
                                    <option value={365}>Shahpur Chakar</option>
                                    <option value={366}>Havelian</option>
                                    <option value={367}>Hattar </option>
                                    <option value={372}>Garhi Dupatta</option>
                                    <option value={373}>Pir Bala </option>
                                    <option value={377}>Chattar</option>
                                    <option value={381}>Pasrur</option>
                                    <option value={382}>Sambrial</option>
                                    <option value={383}>Daska</option>
                                    <option value={384}>Narowal</option>
                                    <option value={385}>Shakargarh</option>
                                    <option value={386}>Head Marralla </option>
                                    <option value={387}>Zaffarwal</option>
                                    <option value={388}>Merajke</option>
                                    <option value={390}>Mandrah</option>
                                    <option value={391}>Qasba Gujrat</option>
                                    <option value={392}>Qadirpur Rawan</option>
                                    <option value={393}>Pahar Pur</option>
                                    <option value={394}>Mehmood Kot</option>
                                    <option value={395}>Kacha Khuh</option>
                                    <option value={396}>Fateh Pur</option>
                                    <option value={397}>Chowk Azam</option>
                                    <option value={398}>Chowk Qureshi</option>
                                    <option value={399}>Chowk Munda</option>
                                    <option value={400}>Basti Lar</option>
                                    <option value={401}>Basti Malook</option>
                                    <option value={402}>Shorkot</option>
                                    <option value={403}>Bala Pir</option>
                                    <option value={404}>Batkhela</option>
                                    <option value={405}>Bhai Pheru</option>
                                    <option value={406}>Phool Nagar</option>
                                    <option value={407}>Dera Murad Jamali</option>
                                    <option value={408}>Dera Allah Yar</option>
                                    <option value={410}>Lower Dir</option>
                                    <option value={411}>Jafarabad</option>
                                    <option value={414}>Mandi Bahauddin</option>
                                    <option value={415}>Mingora</option>
                                    <option value={417}>Panjeri</option>
                                    <option value={418}>Pind Dadan Khan</option>
                                    <option value={419}>Bhan Syedabad</option>
                                    <option value={420}>Choa Saidan Shah</option>
                                    <option value={421}>Dadyal</option>
                                    <option value={422}>Daluwali</option>
                                    <option value={423}>Dhoria</option>
                                    <option value={425}>Jatlan</option>
                                    <option value={426}>Jund</option>
                                    <option value={428}>Khalabat Township</option>
                                    <option value={430}>Kharota Syedan</option>
                                    <option value={431}>Kotli Loharan</option>
                                    <option value={433}>Mandi</option>
                                    <option value={434}>Mangla</option>
                                    <option value={436}>Murree</option>
                                    <option value={437}>Petaro</option>
                                    <option value={438}>Phalia</option>
                                    <option value={441}>Saidan</option>
                                    <option value={442}>Ugoki</option>
                                    <option value={443}>Pattoki</option>
                                    <option value={444}>Bagh</option>
                                    <option value={445}>Rawalakot</option>
                                    <option value={448}>Qaboola</option>
                                    <option value={449}>Kassowal</option>
                                    <option value={450}>Iqbal Nagar</option>
                                    <option value={452}>Mandi Madressa</option>
                                    <option value={453}>Shabqadar</option>
                                    <option value={455}>Katlang</option>
                                    <option value={456}>Toru</option>
                                    <option value={457}>Rustam</option>
                                    <option value={458}>Ambar</option>
                                    <option value={459}>Shewa Adda</option>
                                    <option value={460}>Chota Lahore</option>
                                    <option value={461}>Kabal</option>
                                    <option value={462}>Saidu Sharif</option>
                                    <option value={463}>Shahdadkot</option>
                                    <option value={464}>Sanawan</option>
                                    <option value={465}>Kallar Syedan</option>
                                    <option value={466}>Rawat</option>
                                    <option value={467}>Malakwal</option>
                                    <option value={468}>Khairabad</option>
                                    <option value={469}>Kala Shah Kaku</option>
                                    <option value={470}>Kotri</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className>
                              <div className="row">
                                <div className="col-lg-4 form-group">
                                  <input type="text" className="form-control" name="estimated_weight" id="estimated_weight" placeholder="Estimated Weight" data-rule-required="true" data-msg-required="Estimated Weight is required" />
                                </div>
                                <div className="col-lg-4 form-group">
                                  <input type="text" className="form-control" name="estimated_cod_amount" id="estimated_cod_amount" placeholder="Estimated COD Amount" data-rule-required="true" data-msg-required="Estimated COD Amount is required" />
                                </div>
                              </div>
                            </div>
                            <div className>
                              <div className="row">
                                <div className="col-lg-12 form-group">
                                  <button type="submit" style={{padding: '2px 25px'}} className="btn brown-bg-button-reversed">Calculate Rates</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 border-white secondform second">
                        <img src={price10} className="img-fluid" />
                      </div>
                      <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12 third">
                        <div className="border p-3">
                          <form id="view_rates_calculation_form">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-4">
                                  <label>Account Type:</label>
                                  <input type="text" className="form-control" name="view_account_type" id="view_account_type" readOnly />
                                </div>
                                <div className="col-lg-4">
                                  <label>Shipping Mode:</label>
                                  <input type="text" className="form-control" name="view_shipping_mode" id="view_shipping_mode" readOnly />
                                </div>
                                <div className="col-lg-4">
                                  <label>Service Type:</label>
                                  <input type="text" className="form-control" name="view_booking_type" id="view_booking_type" readOnly />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-3">
                                  <label>Origin:</label>
                                  <input type="text" className="form-control" name="view_origin" id="view_origin" readOnly />
                                </div>
                                <div className="col-lg-3">
                                  <label>Destination:</label>
                                  <input type="text" className="form-control" name="view_destination" id="view_destination" readOnly />
                                </div>
                                <div className="col-lg-3">
                                  <label>Weight:</label>
                                  <input type="text" className="form-control" name="view_weight" id="view_weight" readOnly />
                                </div>
                                <div className="col-lg-3">
                                  <label>COD Amount:</label>
                                  <input type="text" className="form-control" name="view_cod" id="view_cod" readOnly />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Rates</label>
                                  <input type="text" className="form-control" name="view_rates" id="view_rates" readOnly />
                                </div>
                                <div className="col-lg-6 text-right">
                                  <br />
                                  <button type="button" style={{padding: '2px 25px'}} className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section className="Faqs pt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center mb-5">
                                <h2  >Salient Features</h2>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul >
                                                <li className="licolor" >
                                                    Tracking Online Delivery
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor">
                                                    Delivery time of 24 to 48 hours
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Custom Made Service Plans
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    On-time Delivery
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Tracking of Website & Mobile Shipment
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                Ready to Use Plugins for Shopify and OpenCart, etc
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Different Options for Packing Material
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Committed POC
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    SMS alerts regarding Booking, Routing and Delivery
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor">
                                                    Devoted Customer Support
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    High Quality Services Provider Call Center
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Delicate, Special Events & Instruction Shipment Handling
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              {/* <section className="Faqs pt-5 pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <img src={faqmin} className="img-responsive w-50" />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <h2>FAQs</h2>
                      <div className="accordion" id="accordionExample">
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                              <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Q. What is the Account Registration and Activation process?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body">
                              To register for the account opening, log on to dodeliver.hnhclient.xyz/. Click on the Register button and fill out the form. Once the process has been completed, our representative will get in touch with you to activate your account.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingTwo">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Q. How can I book shipments for delivery through DoDeliver?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="card-body">
                              Once your account is activated, you can logon to dodeliver.hnhclient.xyz/ with your account credentials, and click on the booking form, where you can enter shipping information, consignee information, order and product details, cash amount information and delivery
                              mode and service type to book your shipments. You can also book orders in bulk by uploading an excel file filled with the above mentioned information. Other than that, you can also book orders directly from your e-commerce
                              platform by integrating our APIs to your application, or you can also install our plugins for different e-commerce platforms (Shopify, etc.).
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThree">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Q. What is the cut off time to book shipments for pickup?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div className="card-body">
                              <b>For Karachi, Lahore, Hyderabad, and Multan</b><br /> Shipments booked before 03:00 PM on DoDeliver's portal (Sonic) will be picked and dispatched the same day. Shipments booked after 03:00 PM will be Picked on the next
                              working day.<br />
                              <b>For Islamabad and Jehlum</b><br /> Shipments booked before 01:00 PM on DoDeliver's portal (Sonic) will be picked and dispatched the same day. Shipments booked after 01:00 PM will be Picked on the next working day.<br />
                              <b>For Faisalabad and Gujranwala</b><br /> Shipments booked before 02:00 PM on DoDeliver's portal (Sonic) will be picked and dispatched the same day. Shipments booked after 02:00 PM will be Picked on the next working day.<br />
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingFour">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Q. How far and wide does DoDeliver deliver?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                            <div className="card-body">
                              DoDeliver has a wide delivery network of 320 destinations across Pakistan, operated through 33 offices in Karachi, Lahore, Islamabad, Quetta, Peshawar, Hyderabad, Multan, Faisalabad, Gujranwala, Sialkot, Sargodha, Sahiwal, Abbottabad, Bahawalpur,
                              Rahim Yar Khan, Sukkur, Dera Ghazi Khan, Dera Ismail Khan, Wah Cantt, Jhelum, Mirpur Khas, Thatta, Mirpur Azad Kashmir, Muzaffarabad, Nawabshah, Nowshera, Kamra, Kallar Syedan, Haripur, Mansehra, Pano Akil, Mardan,
                              Vehari, Okara.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingFive">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Q. How fast does DoDeliver deliver shipments?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                            <div className="card-body">
                              Within city, shipments will be attempted within 24 hours of the dispatched. Shipments pertaining to KLI (Karachi, Lahore &amp; Islamabad) will be attempted within 1-2 working days after the pickup. Shipments pertaining to all the other destinations will be attempted within 3-4 working days.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingSix">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Q. How many attempts are made for delivery, and how soon does DoDeliver return shipments?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                            <div className="card-body">
                              A maximum of 3 attempts will be made for each shipment, and in case the consignee refuses to accept the Shipment or is found unavailable/ unresponsive during the delivery attempts, our customer support department will confirm the return for such shipments with the consignee before the shipment is being scheduled for return to the shipper. The shipment will be returned to the shipper within 7 working days of return confirmation and he/she will be charged for return as per agreement.
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingSeven">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Q. How does DoDeliver categorize its delivery network into Zones?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>DoDeliver has classified its delivery network into four Tiers or Zones.</p>
                              <p>Zone A refers to major cities like Karachi, Lahore, Islamabad, Faisalabad etc, which are directly connected with each other.</p>
                              <p>Zone B refers to cities which are connected through Zone A cities like Rahim Yar Khan, Sahiwal etc.</p>
                              <p>Zone C refers to smaller cities having relatively lesser frequency and volume of deliveries than Zone B</p>
                              <p>Zone D refers to remote towns and locations having relatively lesser frequency and volume of deliveries than Zone C</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingEight">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                Q. How should I ensure the packaging of my shipment for normal and fragile both?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Shipper are responsible for the packaging of their shipments &amp; it should be packed /sealed with the DoDeliver Labels pasted on it. Shipper can use their own branded / non branded flyers which should be fully sealed, but
                                should avoid using transparent packaging.</p>
                              <p>Sensitive shipments should be marked with a Fragile sticker so as to indicate the Operations to take special care. It's also advisable to use Styrofoam boxes to ship highly fragile products like cosmetics, electronics,
                                etc. All the fragile/sensitive shipments are shipped on shipper's risk.</p>
                              <p>Flyers from any other vendor (Courier Company) would not be accepted &amp; such shipments will be hold in operations at the time of shipment arrival. </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingNine">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Q. Does DoDeliver offer Packaging Material? If yes, then in what sizes? How can I avail them and how would I be charged?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>DoDeliver offers flyers in three sizes i.e. Small 13*9.5 inches (charged at Rs. 10 per unit), Medium 18*12 inches (charged at Rs. 15 per unit), Large 20*14 inches (charged at Rs. 20 per unit).</p>
                              <p>Flyers can only be requested after your account has been activated &amp; first request of flyers delivery will be done on cash only. (future requests can be adjusted in account).</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingTen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                Q. What is the difference between Reimbursement and Corporate Invoicing Accounts?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Reimbursement Accounts are regular COD accounts for which charges are deducted at the time of payments. On the other hand, Corporate Invoicing Accounts are the accounts for which delivery payments are transferred fully and DoDeliver sends an invoice for service charges on weekly, fortnightly or monthly basis. The pricing structure for both the accounts is also different.</p>
                              <p>All the COD accounts fall under the Reimbursement category, whereas accounts for bulk movements (Overland) are categorized as corporate invoicing accounts.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingEleven">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                Q. What is the cycle of payment? How would I as a shipper receive payments for delivery?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Payments for every delivered shipment will be reimbursed to the shipper within 24 Hours of the delivery, through IBFT only.Please note that all the charges pertaining to each shipment will be deducted at the time of payment.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingTwelve">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                Q. On what criteria does DoDeliver charge for shipment delivery? What is the Pricing Policy?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>DoDeliver charges the shipment delivery based on the weight of the shipment delivered. Other components of pricing are cash handling, fuel surcharge or insurance etc and will be applied as per the agreement with the client.</p>
                              <p>Delivery for Out of service or non-service areas will be charged separately after getting the approval from shipper.
                              </p>
                              <p>Minimum Chargeable weight for shipments moved using Overnight delivery service is 0.5Kg.</p>
                              <p>Minimum Chargeable weight for shipments moved using Overland services is 5Kg.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThirteen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                Q. How are shipments weighed? What is the difference between Dense and Volumetric weight?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Shipments weight charges are calculated according to either the dense weight (actual weight of the mass of the shipment) or the volumetric weight (the 3D dimensions of the shipment) whichever is higher.</p>
                              <p>The volumetric weight will be applied based on the following formula:</p>
                              <p>{'{'}Length (cm) X Width (cm) X Height (cm){'}'} /5,000</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingFourteen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                Q. What is the Claim Policy for damages or loses?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseFourteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Claims will only be acceptable for the shipments lost during transit. Moreover, for the accepted claims upto 80% of the value will be reimbursed to the client. Claims will not be acceptable in case of damage due to improper packaging of the product.</p>
                              <p>The claim will be processed once the required documents are furnished. For example: Purchase Slip, Clearing Documents, etc.</p>
                              <p>Any claim for the shipment return/insurance/delivery after 30 days of pick-up will not be entertained. Once the Shipment is being delivered to the consignee, no claim shall be acceptable.</p>
                              <p>DoDeliver will not be held responsible in case of any damage or harm done to shipments owing to unforeseen or cataclysmic events, for example: earthquakes, fires, surges, mobs and strikes etc.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingFifteen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                Q. Can I avail insurance on my shipment delivery? If Yes, then how?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseFifteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>To avail the insurance, customer will have to inform their concerned Account Manager prior to opening the account. The insurance cost borne will be 3% of the value of Shipment for all the general items, for electronic items, it will be 5% of the value of Shipment.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingSixteen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                Q. What are the GST Charges?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseSixteen" className="collapse" aria-labelledby="headingSixteen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Taxes will be applicable on total shipment charges depending on the origin of shipments. 13% GST will be applied to the shipments originating from Sindh, whereas 16% GST will be applied to the shipments originating from Punjab.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingSeventeen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                Q. What Nature of Items are allowable to be shipped through DoDeliver?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseSeventeen" className="collapse" aria-labelledby="headingSeventeen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>You can move any sort of product through DoDeliver, except for the below mentioned categories:</p>
                              <p>Currency, jewelry, Bullion, Antiques, Liquor, Stamps, Precious Metals, Precious Stones, Works of Art, Fire Arms, Plants, Drugs, Explosives, Animals, Perishable goods and items, Negotiable Instruments in bearer form, Lewd Objects, Obscene and Pornographic Material, Industrial Carbons and Diamonds, hazardous or combustible materials, and all other items/articles restricted by IATA (International Air Transport Association), ICAO (International Civil Aviation Organization) and any item whose distribution is regulated by law or by any statute of the Provincial or Federal Government.</p>
                              <p>DoDeliver will have full legal authority to act against the customer in case any such item is found in any shipments.</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingEighteen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
                                Q. What other Value Added Services can I avail?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseEighteen" className="collapse" aria-labelledby="headingEighteen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>DoDeliver provides you the Value Added Services of replacement delivery through which you exchange products with your consignees at their doorsteps, reverse pickups where you can recall orders from your consignees and receive at your doorsteps, delivery or return intercept where you can dispatch an order of the same product from the current location rather than recalling to your address and dispatching again, vendor pickups for marketplaces to arrange timely pickups from their vendors using the dropship model.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingNineteen">
                            <h2 className="mb-0">
                              <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
                                Q. How and When can I contact Customer Support?
                              </button>
                            </h2>
                          </div>
                          <div id="collapseNineteen" className="collapse" aria-labelledby="headingNineteen" data-parent="#accordionExample">
                            <div className="card-body">
                              <p>Feel free to contact our Customer Support through Landline (0213-877-22-22), email <a href="mailto:cs@DoDeliver.pk">(cs@DoDeliver.pk)</a> and through our <a href="https://www.facebook.com/DoDeliverLog">Facebook</a> page. You can also add requests or lodge complaints though our portal Sonic. Our Customer Support department will be operating from 09:00 am ¨C 6:00 pm, Monday to Saturday. All the queries/complaints related to the customer support will be entertained during the time mentioned above.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
              <div className="clearfix" />
              {/*  */}

              {/*  */}
            </div>
            <Footer />
        </div>
    )
}
export default Pricing