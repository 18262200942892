
// import React,{useState,us} from 'react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import callcenter from '../assets/images/images_resize/CallCenterSolution-min.png'
import Channelmanagement from '../assets/images/images_resize/Channelmanagement-min.png'
import payment from '../assets/images/images_resize/Fastest Payments.png'
import warehouse from '../assets/images/images_resize/Warehousingandfulfillment-min.png'
import mile from '../assets/images/images_resize/First&LastMileDeliveries-min.png'
import returnmanagement from '../assets/images/images_resize/ReturnsManagement-01.png'
import techops from '../assets/images/images_resize/TechOps_v.png'
import verticalinternet from '../assets/images/images_resize/VerticallyIntegratedNetwork.png'

import homepopup3 from '../assets/images/Home-Pop-up_03.png'

const Footer = () => {

  const [full_name, setFullname] = useState("")
  const [email, setEmail] = useState("")
  const [brand_name, setBrand] = useState("")
  const [poc, setPoc] = useState("")
  const [image, setImage] = useState("")
  const [contact, setContact] = useState("")
  const [pickup_address, setAddress] = useState("")
  const [pickup_address_alternate, setAltaddress] = useState("")
  const [area, setArea] = useState("")
  const [city, setCity] = useState("")
  const [service, setService] = useState("")
  const [message, setMessage] = useState("")

 const clearit = () => {
   
    setFullname("")
    setEmail("")
    setBrand("")
    setContact("")
    setAddress("")
    setPoc("")
    setAltaddress("")
    setArea("")
    setCity("")
    setService("")
    setImage("")
    setMessage("")
    document.getElementById('img1').value = null
  
 }
  
  const signupform = () => {
    
    alert("hello baloch")

    console.log("subhan shaha brand",image)

  
    if (full_name === ""  ) {
      Swal.fire({
        title: "Oops",
        text: "Fullname is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    } else if (poc === "") {
      Swal.fire({
        title: "Oops",
        text: "Poc no is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (contact === "" ) {
      Swal.fire({
        title: "Oops",
        text: "12 digit Contact no is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (email === "") {
      Swal.fire({
        title: "Oops",
        text: "Email is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (pickup_address === "") {
      Swal.fire({
        title: "Oops",
        text: "pickup_address is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (pickup_address_alternate === "") {
      Swal.fire({
        title: "Oops",
        text: "pickup_address_alternate is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    
    else if (area === "") {
      Swal.fire({
        title: "Oops",
        text: "Client Area is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (city === "") {
      Swal.fire({
        title: "Oops",
        text: "Client city is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (image === "") {
      Swal.fire({
        title: "Oops",
        text: "Image is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    
    else if (service === "") {
      Swal.fire({
        title: "Oops",
        text: "Select Service is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    
    else if (brand_name === "") {
      Swal.fire({
        title: "Oops",
        text: "Image is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    else if (message === "") {
      Swal.fire({
        title: "Oops",
        text: "Write your  Message is required",
        icon: "error",
        confirmButtonColor: "#ed2a26",
      });
    }
    
    

    // console.log("first name is",frist_name , last_name)
    // console.log("last name is", last_name)
    // console.log("last name is", email)
    // console.log("last name is", password)
    // console.log("last name is", contactno)
    // console.log("last name is", profile)

    
    // "fias " yeh hmre key hai postman value backend ki taraf se dia jata hai 
    // second wala apna state ka nam hai

    else{

    

    var formdata = new FormData();
formdata.append("full_name", full_name);
formdata.append("poc",poc);
formdata.append("email", email);
formdata.append("brand_name",brand_name);
formdata.append("pickup_address",pickup_address );
formdata.append("pickup_address_alternate",pickup_address_alternate );
formdata.append("contact",contact );
formdata.append("image",image );
formdata.append("pickup_address",pickup_address );
formdata.append("pickup_address_alternate",pickup_address_alternate );
formdata.append("area",area );
formdata.append("city",city );
formdata.append("service",service );
formdata.append("message",message );
// formdata.append("city",city );



var requestOptions = {
  method: 'POST',
  body: formdata,
  headers: {
    Accept: 'application/json'
  },
  redirect: 'follow'
};

}

fetch("https://dodeliver.hnhtechsolutions.com/api/register", requestOptions)
  .then(response => response.json())
 
  
  .then(result => 
    {
      console.log("result is ==>",result)
      console.log("data==>",result['errors'])
      // console.log("data==>",result['errors.contact'])
      // console.log("data==>",result['errors.email'])
      //result : {error} ==> undefine  ka means agr backend se koi error aae tou means undefine hoga wrna sucess ka message bhejey ga
    // is code ka mtlab hai agarn result['error] 
      if(result['errors'] == undefined){

        Swal.fire({
         title:result.message,
        //  text:'You clicked the button!',
         // window.input.value = ''
         icon:'success' })
   
       }

       else if (result['errors']['email'] != undefined) {

        console.log("hit email")
        Swal.fire({
          title: "Oops",
          text: result['errors']['email'],
          icon: "error",
          confirmButtonColor: "#ed2a26",
        });
      }

      else if (result['errors']['contact'] != undefined) {

        console.log("hit contact")

        Swal.fire({
          title: "Oops",
          text: result['errors']['contact'],
          // text: "Contant has been already taken",
          icon: "error",
          confirmButtonColor: "#ed2a26",
        });
      }

       


      

      else {

        Swal.fire({
          title: "Oops",
          text: result.message,
          icon: "error",
          confirmButtonColor: "#ed2a26",
        });
      }
   
    }
    )
    

    
  .catch(error => console.log('error', error));
  }

   

    return (
        <div>
            <section className='footer' >
                <div id="copyrights">
                    <div className="container clearfix">
                        <div className="row no-gutters">
                            <div className='col-12 text-center mb-2'>
                                <h2 style={{ color: '#e62e2d' }}>Zonal Offices</h2>
                            </div>
                            
                        </div>
                        <div className='row'>

                            
                            <div className="col-md-4 text-center">
                                <h3 style={{ color: '#fff' }}>Karachi</h3>
                                <span>Contact: 02133383990.</span>
                                <h3 style={{ color: '#fff' }}>Chichawatni</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Faisalabad</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Rawalpindi</h3>
                                <span>Will Be Shared Soon</span>
                            </div>
                            <div className="col-md-4 text-center">
                                <h3 style={{ color: '#fff' }}>Mianwali</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Khanwal</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Quetta</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Lahore</h3>
                                <span>Will Be Shared Soon</span>
                            </div>
                            <div className="col-md-4 text-center">
                                <h3 style={{ color: '#fff' }}>Mian chunnu</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Multan</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Hyderabad</h3>
                                <span>Will Be Shared Soon</span>
                                <h3 style={{ color: '#fff' }}>Islamabad</h3>
                                <span>Will Be Shared Soon</span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <footer className="footer">
                    <div id="copyrights">
                        <div className="container-fluid clearfix">
                            <div className="row no-gutters">
                                <div className="col-4">
                                    Copyrights © 2022 All Rights Reserved by DoDeliver.
                                    Power By
                                    <Link className='ml-2' data-toggle="modal" data-target=".360-DegreEcommerce" to={"/"} >
                                        Omni-Group
                                    </Link>


                                </div>
                                <div className="col-8 tright">

                                    <Link className='m-2' to={'/termsandcondition'} >
                                        Terms and Condition
                                    </Link>

                                    <a href='tel:+02133383990' target="_blank" className="d-inline-block text-white" >
                                        <i className="fa fa-phone" aria-hidden="true" /> 021-33383990
                                    </a>
                                    <span className="middot" />

                                    <a href='mailto:info@dodeliver.com.pk' target="_blank" className="d-inline-block text-white" >
                                        <i className="fa fa-phone" aria-hidden="true" /> info@dodeliver.com.pk
                                    </a>
                                    <span className="middot" />

                                    <a href='https://www.facebook.com/DoDeliver786' target="_blank" className="d-inline-block text-white" >
                                        <i className="fa fa-facebook" aria-hidden="true" /> DoDeliver
                                    </a>
                                    <span className="middot" />
                                    < a href='https://www.linkedin.com/company/do-deliver' target="_blank" className="d-inline-block text-white">
                                        <i className="fa fa-linkedin" aria-hidden="true" /> DoDeliver
                                    </a>
                                    <span className="middot" />
                                    <a href='https://www.instagram.com/accounts/login/' target="_blank" className="d-inline-block text-white" >
                                        <i className="fa fa-instagram" aria-hidden="true" /> DoDeliver
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>

            {/*  */}
            <div className="modal fade 360-DegreEcommerce" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header text-center">
                            <h3 className="modal-title w-100 text-capitalize">Omni Group </h3>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                        <p><img src={callcenter} className="img-responsive" />
                                        </p>
                                        <h3>Call Center Solution</h3>
                                        <p>Our specialized call center solution takes care of orders as well as returns
                                            confirmation and provides a seamless experience to you and your customers.
                                        </p>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                        <p><img src={warehouse} className="img-responsive" /></p>
                                        <h3>Warehousing &amp; Fulfillment</h3>
                                        <p>We help customers manage their stock efficiently, by ensuring proper picking,
                                            multiple layers of quality checks, packing, labelling and dispatching of orders.
                                        </p>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                        <p><img src={mile} className="img-responsive" /></p>
                                        <h3> First &amp; Last Mile Deliveries</h3>
                                        <p>We bring you the most innovative first mile and last mile logistics services in terms
                                            of velocity and widespread network with the value added services of returns
                                            management and reverse logistics solutions.</p>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                        <p><img src={Channelmanagement} className="img-responsive" />
                                        </p>
                                        <h3>Channel Management</h3>
                                        <p>Do Deliver’s Channel Management service is a ‘one-window’ solution that enables you to
                                            fulfill orders generated from multiple sources, be it your own online store or from
                                            different marketplaces.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}

            {/* form ==>Customer Registration Form */}
            <div className="modal fade popupwrap" id="myModal" tabIndex={-1} role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <button type="button" style={{ position: 'absolute', zIndex: 9999, right: '0px', textAlign: 'right', padding: '10px', color: '#fff', background: '#000', opacity: 1 }} className="close" data-dismiss="modal">×</button>
            <div className="modal-body" id="letsGetStarted">
              <div className="form-wrapper">
                <div id="container" style={{ margin: '0px' }}>
                  <h1>Customer Registration Form  <br /></h1>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <form action="#" method="" id="contact_form" className="pl-4">
                        <div className="row">
                          <div className="name col-lg-6 form-group">
                            <label htmlFor="name" />Name:
                            <input type="text" 
                            placeholder="Client Name" 
                            value={full_name}
                            onChange={(e)=>{setFullname(e.target.value)}}
                            name="full_name"
                             id="full_name"

                            //  data-rule-required="true"
                             autoComplete="off"
                              data-msg-required="Full Name is required" />
                          </div>
                          <div className="phone_number col-lg-6 form-group danger">
                            <label htmlFor="POC" />Poc No:
                            <input type="text" placeholder="POC #" 
                            value={poc}
                            onChange={(e)=>{setPoc(e.target.value)}}
                            name="poc"
                             id="poc"
                             data-rule-required="true" data-msg-required="POC is required" />
                          </div>
                          <div className="phone_number col-lg-6 form-group danger">
                            <label htmlFor="Contact" /> Contact No:
                            <input type="text" placeholder="Enter 12 Digit Number "
                             name="contact" 
                             value={contact}
                             onChange={(e)=>{setContact(e.target.value)}}
                             id="contact"
                             maxlength="12" pattern="\d{12}"
                              data-rule-required="true" data-msg-required="Phone Number is required" />
                          </div>
                          <div className="email col-lg-6 form-group">
                            <label htmlFor="email" />Client Email
                            <input type="email" placeholder="Example@xyz.com "
                             autoComplete="none"
                             value={email}
                             onChange={(e)=>{setEmail(e.target.value)}}
                              name="email" 
                              id="email"  />
                          </div>
                          <div className="name col-lg-12 form-group">
                            <label htmlFor="address" />Client Pickup Address
                            <input type="text" placeholder="Client Pickup Address"
                            value={pickup_address}
                            onChange={(e)=>{setAddress(e.target.value)}}
                             name="pickup_address"
                              id="pickup_address" data-rule-required="true" data-msg-required="address is required" />
                          </div>
                          <div className="name col-lg-12 form-group">
                            <label htmlFor="address" />Client Pickup Address 2
                            <input type="text" placeholder="Client Pickup Address 2" 
                            
                            value={pickup_address_alternate}
                            onChange={(e)=>{setAltaddress(e.target.value)}}
                             name="pickup_address_alternate"
                            
                            id="pickup_address_alternate" data-rule-required="true" data-msg-required="address2 is required" />
                          </div>
                          <div className="name col-lg-6 form-group">
                            <label htmlFor="Clientarea" />Client Area
                            <input type="text" placeholder="Client Area" 
                             name="area"
                             value={area}
                            onChange={(e)=>{setArea(e.target.value)}}
                             
                             id="area" data-rule-required="true" data-msg-required="area is required" />
                          </div>
                          <div className="name col-lg-6 form-group">
                            <label htmlFor="Clientcity" />Client City
                            <input type="text" placeholder="Client City" 
                            name="city"
                            value={city}
                            onChange={(e)=>{setCity
                              (e.target.value)}}
                             
                            
                            id="city" data-rule-required="true" data-msg-required="city is required" />
                          </div>
                          <div className="name mb-4 pb-3 col-lg-12 form-group">
                            <label htmlFor="Clientlogo" />Upload Logo
                            <input type="file" placeholder="Client logo" 
                            name="image"
                            id='img1'
                           
                            onChange={(e)=>setImage(e.target.files[0])}
                               />
                          </div>
                          
                          <div className="col-lg-6 form-group">
                            <label htmlFor="service_id" />Select Services
                            <select
                             name="service" 
                            value={service}
                            onChange={(e)=>{setService (e.target.value)}}

                            id="service" 
                            
                            data-rule-required="true" data-msg-required="Services is required">
                              <option value>Select Services</option>
                              <option value={1}>International</option>
                              <option value={2}>Warehousing</option>
                              <option value={3}>Bulk Movements (Overland)</option>
                              <option value={4}>Cash On Delivery</option>
                              <option value={5}>Corporate Account (Non COD)</option>
                            </select>
                          </div>
                          {/* <div className="company col-lg-6 form-group" id="companydiv">
                            <label htmlFor="company" />
                            <input type="text" placeholder="Company Name" name="company" id="company" data-rule-required="true" data-msg-required="Company is required" />
                          </div> */}
                          <div className="brand col-lg-6 form-group" id="branddiv">
                            <label htmlFor="brand" />Brand Name
                            <input type="text" placeholder="Brand Name"
                              value={brand_name}
                              onChange={(e)=>{setBrand(e.target.value)}}
                             name="brand_name" 
                             id="brand" data-rule-required="true" data-msg-required="Brand is required" />
                          </div>
                          <div className="message col-lg-12 form-group">
                            {/* <label htmlFor="message" />Write Your Message */}
                            <textarea
                             name="message"
                             id="message"
                             value={message}
                             onChange={(e)=>{setMessage(e.target.value)}}
                             
                             placeholder="Write Your Message"  cols={30} rows={5} data-rule-required="true" data-msg-required="Message is required" defaultValue={""} />
                          </div>
                          {/* Add Captcha */}
                          <div className="col-lg-12 form-group">
                            <span className="d-none" id="captcha_error" style={{ color: '#ff6961', fontSize: 'small' }} />
                          </div>
  
                           <div className='row'>
                           <div className="submit col-lg-6 mb-4">
                            <input type="button" onClick={signupform} defaultValue="SignUp Now" id="form_button" />
                          </div>
                             
                          <div className="submit col-lg-6">
                            <input type="button" onClick={clearit} defaultValue="Clear Input field " id="form_button" />
                          </div>  

                           </div>
                          

                        </div>
                      </form>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <img alt="image" src={homepopup3} className="pr-4 img-fluid" />
                      {/* <img alt="image"  className="pr-4 img-fluid" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/*  */}

        </div>
    )
}

export default Footer
