import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2'
import Services03 from "../assets/images/Services_03.png";
// import Services07 from "../assets/images/Services_07.png";
import Services07 from "../assets/images/Home_07.png";
import Home15 from "../assets/images/Home_15.png";

import popup3 from "../assets/images/Home-Pop-up_03.png";

import fulfil from "../assets/images/Fulfilment-min.png";

import homepopup3 from '../assets/images/Home-Pop-up_03.png'





import service11 from "../assets/images/Services_11.png";
import service15 from "../assets/images/Services_15.png";
import service19 from "../assets/images/Services_19.png";
import service22 from "../assets/images/Services_22.png";
// import Channelmanagementmin from "../assets/images/Channelmanagement-min.png";

import callcenter from '../assets/images/images_resize/CallCenterSolution-min.png'
import Channelmanagement from '../assets/images/images_resize/Channelmanagement-min.png'
import payment from '../assets/images/images_resize/Fastest Payments.png'
import warehouse from '../assets/images/images_resize/Warehousingandfulfillment-min.png'
import mile from '../assets/images/images_resize/First&LastMileDeliveries-min.png'
import returnmanagement from '../assets/images/images_resize/ReturnsManagement-01.png'
import techops from '../assets/images/images_resize/TechOps_v.png'
import verticalinternet from '../assets/images/images_resize/VerticallyIntegratedNetwork.png'

import { useState, useEffect } from 'react';
import AOS from 'aos';
function Ourservices() {
    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);
    const $ = window.$
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ahmedalert = () =>{
        Swal.fire({
          title:'Good job!',
          text:'You clicked the button!',
          icon:'success' })
      }
    return (
        <div>
            <Header />
            <div>

           

                {/* <div className="modal fade 360-DegreEcommerce" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h3 className="modal-title w-100 text-capitalize">360-DegreEcommerce</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p><img src={callcenter} className="img-responsive" />
                                            </p>
                                            <h3>Call Center Solution</h3>
                                            <p>Our specialized call center solution takes care of orders as well as returns
                                                confirmation and provides a seamless experience to you and your customers.
                                            </p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p><img src={warehouse} className="img-responsive" /></p>
                                            <h3>Warehousing &amp; Fulfillment</h3>
                                            <p>We help customers manage their stock efficiently, by ensuring proper picking,
                                                multiple layers of quality checks, packing, labelling and dispatching of orders.
                                            </p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p><img src={mile} className="img-responsive" /></p>
                                            <h3> First &amp; Last Mile Deliveries</h3>
                                            <p>We bring you the most innovative first mile and last mile logistics services in terms
                                                of velocity and widespread network with the value added services of returns
                                                management and reverse logistics solutions.</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p><img src={Channelmanagement} className="img-responsive" />
                                            </p>
                                            <h3>Channel Management</h3>
                                            <p>Do Deliver’s Channel Management service is a ‘one-window’ solution that enables you to
                                                fulfill orders generated from multiple sources, be it your own online store or from
                                                different marketplaces.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="modal fade CashOnDelivery" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h3 className="modal-title w-100">CASH ON DELIVERY</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p className="text-center"><img src={payment} className="img-responsive" /></p>
                                            <h3>Fastest Payments</h3>
                                            <p>Do Deliver offers the fastest cash payback of 24 hours, which helps you maintain your cash
                                                flows and scale your business simultaneously.</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p className="text-center"><img src={returnmanagement} className="img-responsive" /></p>
                                            <h3>Returns Management</h3>
                                            <p>Our unique services of Delivery Return Intercept, 100% Verified Returns, Reattempt
                                                Management module, Reverse Pickups and Replacement Deliveries help you manage your
                                                shipments efficiently and effectively.</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p className="text-center"><img src={verticalinternet} className="img-responsive" /></p>
                                            <h3> Vertically Integrated Network</h3>
                                            <p>Do Deliver ensures efficient movement of goods from the first mile to the last mile across
                                                Pakistan through a dedicated supply chain network, designed to match ever-changing
                                                e-commerce logistics demands.</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-lg-6 col-xs-12 text-center">
                                            <p><img src={techops} className="img-responsive" /></p>
                                            <h3>TechOps</h3>
                                            <p>As an e-commerce logistics solution provider, we employ the ‘TechOps’ methodology
                                                that directs us to constantly use technological advances in improving, facilitating
                                                and opening innovative ways of providing services through our core Operations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade Omni-Channel" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h3 className="modal-title w-100">OMNI-CHANNEL E-COMMERCE</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12-col-lg-12-col-sm-12 text-center">
                                            <img src={Channelmanagement} className="img-responsive" />
                                            <p>Our Omni-Channel E-commerce management system makes it easy to dispatch orders from
                                                multiple retail outlets and vendors utilizing the store locations and inventories
                                                for swift deliveries at lower costs. Designed specifically for online marketplaces
                                                as well as growing retail brands, this solution enables them to use the potential of
                                                their existing retail infrastructure to manage their online businesses.</p>
                                            <p>The solution offers brands to use their retail stores as fulfillment centers and
                                                shipping orders from the closest possible solution to the customer. The benefits of
                                                such an approach is not just limited to optimization, but allows a brand to scale
                                                more effectively and efficiently.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade Warehousing" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h3 className="modal-title w-100">WAREHOUSING</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12-col-lg-12-col-sm-12 text-center">
                                            <img src={warehouse} className="img-responsive" style={{ maxWidth: '250px' }} />
                                            <p>Do Deliver's unique E-tail Warehousing solution comes at rescue for your e-commerce
                                                business, with complete control of order fulfilment and stock management.</p>
                                            <p>Our warehousing system ensures timely dispatches and providing real-time data
                                                analytics for stock and order management, along with integration to your various
                                                e-commerce platforms, including native and third party websites
                                                and apps.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade Fulfilment" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <h3 className="modal-title w-100">FULFILMENT</h3>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12-col-lg-12-col-sm-12 text-center">
                                            <img src={fulfil} className="img-responsive" />
                                            <p>Our state-of-the-art fulfilment system ensures that every order request is being
                                                properly picked, passed through quality checks and fulfilled.</p>
                                            <p>Do Deliver’s fulfilment solution ranges from dedicated warehousing to omni-channel
                                                inventory management, using the reach of retail and incorporating the seamless
                                                experience of e-commerce management, ensuring reliable and real-time inventory
                                                management, wherever and whenever needed.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div class="inner-slider" style="background:#ebebeb; padding:30px; width:100%;">
		<div class="container text-center">
			<h2 class="text-black">SERVICES</h2>
		 </div>
	</div> */}
                <div className="container">
                    <section className="parallax mt-2 mb-5">
                        <div className="row align-items-center justify-content-center">
                            <div data-aos="fade-left" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                                <img src={Services03} className="img-fluid" />
                            </div>
                            <div data-aos="fade-right" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                                <div className="container">
                                    <h2 className="text-capitalize">COD</h2>
                                    <p>Today's most in demand service is cash on delivery service that is offered by Do Deliver. The trend of online shopping is constantly evolving and the demand for safe shopping products is also increasing at the customer's doorstep. Payment is only applicable upon delivery and the online store can choose from the different functions that Do Deliver provides to make the service pack plan more convenient.</p>
                                    {/* <button className="btn brown-bg-button" data-toggle="modal" data-target=".360-DegreEcommerce">See More</button> */}
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="parallax mb-5">
                        <div className="row align-items-center justify-content-center">
                            <div data-aos="fade-left" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                                <div className="container">
                                    <h2>Restaurants Delivery Management</h2>
                                    <p>Do Deliver has helped restaurants who always ended up hiring riders lacking fundamental and essential ethics but also were highly unprofessional. Being in the industry full of scams, Do Deliver ensures no fraudulent acts in terms of payments, CODs, late or no deliveries at all after taking money. Do Deliver currently is working with most prestigious and reputable food chains and franchises in Karachi.  Recently, it has started working with a Canadian application “Vdropp” which is operational in many cities of Pakistan; including, Karachi, Sialkot, Multan, and Quetta. From delivering your menu and sending weekly reports to improving your performance, we will help you at each and every step.</p>
                                    {/* <button type="button" className="btn brown-bg-button" data-toggle="modal" data-target=".CashOnDelivery">See More</button> */}
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                            <div data-aos="fade-right" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0 order-first order-sm-last">
                                <img src={Services07} className="img-fluid" />
                            </div>
                        </div>
                    </section>
                    <section className="parallax mb-5">
                        <div className="row align-items-center justify-content-center">
                            <div data-aos="fade-left" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0  ">
                                <img src={Home15} className="img-fluid" />
                            </div>
                            <div data-aos="fade-right" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                                <div className="container">
                                    <h2>E.COMM SOLUTIONS</h2>
                                    <p>At Do Deliver Solutions, we have the efficient and modern tools in warehouse management along with high quality distribution models. Our main objective is to streamline the business process by providing customers with much needed convenience and the introduction of new digital solution. The integration of solution in the customers and business applications can make the shipping process effortless.
                                    </p>
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="parallax mb-5">
                        <div className="row align-items-center justify-content-center">
                            <div data-aos="fade-left" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0">
                                <div className="container">
                                    <h2>SHIPMENTS</h2>
                                    <p>Do Deliver dominates the shipping industry by delivery to every corner of Pakistan at affordable rates. We continuously strive to deliver high quality services to our customers at affordable rates. With courteous and approachable staff, we ensure providing remarkable services to our valued customers. Reason being, each client has its own concerns for which Do Deliver schedules best rates and packages. Ensuring about the appropriate needs of the clients, it doesn’t only guide but also sets a free of cost sessions where clients are taken into confidence to share business agendas to suggest something that will grow and stabilize the business. Moreover, time is not fixated because Do Deliver emphasizes on solutions by providing right number of trained riders. For that, at times, it takes lesser time than usual, and other times it takes more time.</p>
                                    {/* <button type="button" className="btn brown-bg-button" data-toggle="modal" data-target=".Omni-Channel">See More</button> */}
                                    <button type="button" className="btn brown-bg-button-reversed" data-toggle="modal" data-target="#myModal">Get Started</button>
                                </div>
                            </div>
                            <div data-aos="fade-right" className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 m-0 order-first order-sm-last">
                                <img src={Channelmanagement} className="img-fluid" />
                            </div>
                        </div>
                    </section>
                </div>
                <div className="clearfix" />

                {/*  */}

                <section className="Faqs pt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center mb-5">
                                <h2  >Salient Features</h2>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul >
                                                <li className="licolor" >
                                                    Tracking Online Delivery
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor">
                                                    Delivery time of 24 to 48 hours
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Custom Made Service Plans
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    On-time Delivery
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Tracking of Website & Mobile Shipment
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                Ready to Use Plugins for Shopify and OpenCart, etc
                                                </li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="accordion" id="accordionExample">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Different Options for Packing Material
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Committed POC
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    SMS alerts regarding Booking, Routing and Delivery
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor">
                                                    Devoted Customer Support
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    High Quality Services Provider Call Center
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <ul>
                                                <li className="licolor" >
                                                    Delicate, Special Events & Instruction Shipment Handling
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>Name:</label>
                                            <input type="text" className="form-control" name="name" id="Name" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Email:</label>
                                            <input type="text" className="form-control" name="email" id="email" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>City:</label>
                                            <input type="text" className="form-control" name="city" id="city" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Number:</label>
                                            <input type="text" className="form-control" name="number" id="number" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label>Services Requirment:</label>
                                            <input type="text" className="form-control" name="servicesrequirment" id="servicesrequirment" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Social Media Page:</label>
                                            <input type="text" className="form-control" name="socialmediapage" id="socialmediapage" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-lg-12 text-left">
                                            <br />
                                            {/* <button className="btn brown-bg-button-reversed" type='button' onClick={ahmedalert} >ahmedshow</button> */}
                                            <button type="button" style={{ padding: '2px 25px' }} className="btn brown-bg-button-reversed"  >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                {/*  */}

            </div>

            <Footer />
        </div>
    )
}
export default Ourservices